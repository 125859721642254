import {
  Component,
  TemplateRef,
  ViewChild,
  PLATFORM_ID,
  Inject,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, Observable, lastValueFrom } from 'rxjs';
import { Select2Data, Select2UpdateEvent } from 'ng-select2-component';
import { ProvinceService } from '@/app/shared/services/province.service';
import {
  District,
  Province,
  Ward,
} from '@/app/shared/interface/province.interface';
import { CommonService } from '../../services/common.service';
import { DeliveryAddress, StatusAddress } from '../../interface/user.interface';
import { AddressService } from '../../services/address.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss'],
})
export class AddressModalComponent {

  @Input() address: DeliveryAddress;

  @Output() resultForm = new EventEmitter<DeliveryAddress>();
  @Output() resetAddress = new EventEmitter();

  public idaddress: string;
  public typeModal: number = 0;
  public form: FormGroup;
  public closeResult: string;
  public modalOpen: boolean = false;
  public states$: Observable<Select2Data>;
  @ViewChild('addressModal', { static: false })
  AddressModal: TemplateRef<string>;
  public province: Province;
  public provinceCode: number = 0;
  public districtCode: number = 0;
  public selectedProvince: number;
  public selectedDistrict: number;
  public selectedWard: number;
  public checkDefault: boolean = false;
  public provinces: Province[] = [];
  public districts: District[] = [];
  public wards: Ward[] = [];
  public bodyCreateAddress: DeliveryAddress;

  select2Provinces: any;
  select2Districts: any;
  select2Wards: any;

  loadingDistricts: boolean = false;
  loadingWards: boolean = false;

  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object,
    private formBuilder: FormBuilder,
    private provinceService: ProvinceService,
    private commonService: CommonService,
    private addressService: AddressService,
    private toastr: ToastrService
  ) {
    this.form = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      province: new FormControl('', [Validators.required]),
      district: new FormControl('', [Validators.required]),
      ward: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      phone: new FormControl('', [
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(10),
      ]),
    });
  }

  async ngOnInit() {
    await this.getProvincesInfo();
  }

  async ngOnChanges() {
    // this.form.reset();
    if (this.address) {
      this.typeModal = 1;
      this.form.patchValue({
        name: this.address?.receiverName?.toString(),
        province: this.address?.province?.code?.toString(),
        district: this.address?.province?.districts[0]?.code?.toString(),
        ward: this.address?.province?.districts[0]?.wards[0]?.code?.toString(),
        address: this.address?.address?.toString(),
        phone: this.address?.receiverPhone?.toString(),
      });
      this.idaddress = this.address?.id;
      this.selectedProvince = this.address?.province?.code;

      //districts
      this.loadingDistricts = true;
      const districts = await lastValueFrom(this.provinceService.getProvinceInfoByDepth(this.selectedProvince, this.districtCode));
      if (districts) {
        this.districts = districts[0].districts;
        this.setSelect2DistrictData();
        //now set selected district
        this.selectedDistrict = this.address?.province?.districts[0]?.code;

        //wards
        this.loadingWards = true;
        const wards = await lastValueFrom(this.provinceService.getProvinceInfoByDepth(this.selectedProvince, this.selectedDistrict));
        if (wards) {
          this.wards = wards[0].districts[0].wards;
          this.setSelect2WardData();
          //now set selected ward
          this.selectedWard = this.address?.province?.districts[0]?.wards[0]?.code;
        }
        this.loadingWards = false;
      }
      this.loadingDistricts = false;

      this.checkDefault = this.address?.status == 0 ? true : false;
    } else {
      this.typeModal = 0;
      this.resetForm();
    }
  }

  @Input() addresses: DeliveryAddress[] = [];

  async getProvincesInfo() {
    //call api get provinces
    const res = await lastValueFrom(this.provinceService.getProvinceInfoByRegion());
    if (res) {
      this.provinces = res;
      this.select2Provinces = this.provinces.map(
        (province: Province) =>
          new Object({
            value: province.code,
            label: province.name,
          })
      );
    }
  }

  setSelect2DistrictData() {
    this.select2Districts = this.districts.map(
      (district: District) =>
        new Object({
          value: district.code,
          label: district.name,
        })
    );
  }

  setSelect2WardData() {
    this.select2Wards = this.wards.map(
      (ward: Ward) =>
        new Object({
          value: ward.code,
          label: ward.name,
        })
    );
  }

  async loadDistrictsByProvince($event: any) {
    if ($event.value && !this.loadingDistricts && this.selectedProvince != $event.value) {
      this.loadingDistricts = true;
      const res = await lastValueFrom(this.provinceService.getProvinceInfoByDepth($event.value, this.districtCode));
      if (res) {
        this.districts = res[0].districts;
        this.selectedProvince = $event.value;
        this.setSelect2DistrictData();
        this.select2Wards = [];
        this.loadingDistricts = false;
      }
    }
  }

  //get ward by district
  async loadWardsByDistrict($event: any) {
    if ($event.value && !this.loadingWards && this.selectedDistrict != $event.value) {
      this.loadingWards = true;
      const res = await lastValueFrom(this.provinceService.getProvinceInfoByDepth(this.selectedProvince, $event.value));
      if (res) {
        this.wards = res[0].districts[0].wards;
        this.selectedDistrict = $event.value;
        this.setSelect2WardData();
        this.selectedWard = this.address?.province?.districts[0]?.wards[0]?.code;
        this.loadingWards = false;
      }
    }
  }

  resetForm() {
    this.form.reset();
    this.select2Districts = [];
    this.select2Wards = [];
    this.selectedProvince = 0;
    this.selectedDistrict = 0;
    this.selectedWard = 0;
  }

  //open modal
  async openModal() {
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalOpen = true;
      this.modalService
        .open(this.AddressModal, {
          ariaLabelledBy: 'address-add-Modal',
          centered: true,
          windowClass: 'theme-modal  modal-lg',
        })
        .result.then(
          (result) => {
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  private getDismissReason(reason: ModalDismissReasons): string {
    if (this.typeModal == 0) {
      this.resetForm();
    }
    else {
      this.resetAddress.emit();
    }
    
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  async submit() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      // check valid form
      this.bodyCreateAddress = new DeliveryAddress();

      var res = await lastValueFrom(
        this.provinceService.getProvinceInfoByDepth(
          this.form.get('province')?.value,
          this.form.get('district')?.value
        )
      );

      if (res) {
        this.province = res[0];
        this.province.districts = this.province.districts.filter(
          (a) => a.code == this.form.get('district')?.value
        );
        this.province.districts[0].wards =
          this.province.districts[0].wards.filter(
            (a) => a.code == this.form.get('ward')?.value
          );
      }

      this.bodyCreateAddress.address = this.form.get('address')?.value;
      this.bodyCreateAddress.receiverName = this.form.get('name')?.value;
      this.bodyCreateAddress.receiverPhone = this.form
        .get('phone')
        ?.value.toString();
      this.bodyCreateAddress.province = this.province;
      this.bodyCreateAddress.id = this.idaddress;
      this.bodyCreateAddress.status = this.checkDefault ? 0 : 1;

      //check if address is default and there is no default address
      if (
        this.addresses.length < 1 &&
        !this.checkDefault &&
        this.typeModal == 1
      ) {
        this.toastr.warning('Phải có ít nhất 1 địa chỉ mặc định !', '', {
          positionClass: 'toast-top-right',
        });
        return;
      }
      if (!this.checkDefault && this.typeModal == 1) {
        const foundAddress = this.addresses.find(
          (address) => address.id === this.bodyCreateAddress.id
        );
        if (foundAddress) {
          foundAddress.status = StatusAddress.Active;
          const countStatusOne = this.addresses.filter(
            (address) => address.status === 0
          ).length;
          if (countStatusOne === 0) {
            foundAddress.status = StatusAddress.Default;
            this.checkDefault = true;
            this.toastr.warning('Phải có ít nhất 1 địa chỉ mặc định !', '', {
              positionClass: 'toast-top-right',
            });
            return;
          }
        }
      }

      if (this.typeModal == 1) {
        this.addressService
          .updateUserAddress(JSON.stringify(this.bodyCreateAddress))
          .subscribe((res) => {
            if (res) {
              this.toastr.success('Cập nhật địa chỉ thành công', '', {
                positionClass: 'toast-top-right',
              });
              this.resultForm.emit(this.bodyCreateAddress);
              this.form.reset();
            } else {
              this.toastr.error('Cập nhật địa chỉ thất bại', '', {
                positionClass: 'toast-top-right',
              });
            }
          });
      } else {
        this.addressService
          .createUserAddress(JSON.stringify(this.bodyCreateAddress))
          .subscribe((res) => {
            if (res) {
              this.toastr.success('Thêm địa chỉ thành công', '', {
                positionClass: 'toast-top-right',
              });
              this.resultForm.emit(this.bodyCreateAddress);
              this.form.reset();
            }
          });
      }

      this.modalService.dismissAll();
      this.modalOpen = false;
    }
  }

  changeDefaultAddress(event: any) {
    if (event.target.checked) {
      this.checkDefault = true;
      this.bodyCreateAddress.status = 0;
    } else {
      this.checkDefault = false;
      this.bodyCreateAddress.status = 1;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
}
