import { Component, Inject, PLATFORM_ID, TransferState } from '@angular/core';
import { AuthService, TOKEN } from '../../services/auth.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-incomplete-info-warning',
  templateUrl: './incomplete-info-warning.component.html',
  styleUrl: './incomplete-info-warning.component.scss'
})
export class IncompleteInfoWarningComponent {

  userTier: number = 1;

  constructor(
    private authService: AuthService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      
      const token = this.transferState.get(TOKEN, '');
      if (token) {
        this.userTier = Number(this.authService.getUserTier());
      }
    }
   }
}
