import { Cart } from '@/app/shared/interface/cart.interface';
import { Order, OrderModel, PaymentMethod } from '@/app/shared/interface/order.interface';
import { BankInfo, QRData } from '@/app/shared/interface/qrcode.interface';
import { AuthService } from '@/app/shared/services/auth.service';
import { CommonService } from '@/app/shared/services/common.service';
import { SignalrService } from '@/app/shared/services/notification.service';
import { OrderService } from '@/app/shared/services/order.service';
import { QRCodeService } from '@/app/shared/services/qrcode.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrl: './thank-you.component.scss',
})
export class ThankYouComponent {

  public order: Order;
  public orderId: string;

  isPaid: boolean = false;
  
  qrImgSrc: string;
  qrData: QRData = {
    accNo: '',
    accName: '',
    bank: '',
    amount: 0,
    des: ''
  }

  isAuthenticated: boolean = false;

  constructor(
    private router: Router,
    private orderService: OrderService,
    private qrCodeService: QRCodeService,
    private notificationService: SignalrService,
    private authService: AuthService,
    private commonService: CommonService
  ) {
    this.isAuthenticated = this.authService.isAuthenticated();

    if (this.isAuthenticated) {
      const nav = this.router.getCurrentNavigation();
      if (nav && nav.extras.state) {
        this.orderId = (nav.extras.state as Order).orderId;
        this.getOrderDetail(this.orderId);
      } else {
        this.router.navigateByUrl('quick-order', { replaceUrl: true });
      }
    }
    else {
      const nav = this.router.getCurrentNavigation();
      if (nav && nav.extras.state) {
        const cart = nav.extras.state as Cart;
        this.order = {
          orderId: cart.userInfo.phone + "#" + new Date().getTime(),
          finalPrice: cart.finalPrice,
          orderStatus: 0,
          receiverInfo: {
            address: cart.userInfo.address,
            email: "",
            phone: cart.userInfo.phone,
            userName: cart.userInfo.userName
          },
          items: cart.items.map(item => {
            return {
              imgUrl: item.imgUrl,
              name: item.name,
              price: item.price,
              productID: item.productID,
              quantity: item.quantity,
              totalPrice: item.price * item.quantity,
              paymentMethod: {
                value: cart.paymentMethod!,
                label: cart.paymentMethod!
              }
            }
          })
        }
        localStorage.removeItem('cart');
        this.commonService.setCart(new Cart());
      } else {
        this.router.navigateByUrl('quick-order', { replaceUrl: true });
      }
    }
  }

  ngOnInit() {
    if (!this.isAuthenticated) {
      this.isPaid = true;
    }
    else {
      this.isPaid = false;
      this.notificationService.getPaymentNotify.subscribe({
        next: (res: boolean) => {
          this.isPaid = res;
        }
      });
    }
  }

  getOrderDetail(orderId: string) {
    const body = {
      orderId: orderId
    }
    this.orderService.getOrders(JSON.stringify(body)).subscribe({
      next: (res: OrderModel) => {
        if (res && res.orderHistory) {
          this.order = res.orderHistory[0];
          this.getBankInfo();
        }
      }
    });
  }

  getBankInfo() {
    this.qrCodeService.getBankInfo().subscribe({
      next: (res: BankInfo) => {
        if (res) {
          this.qrData.accNo = res.bankNumber;
          this.qrData.accName = res.bankName;
          this.qrData.bank = res.bankCode;
          this.qrData.amount = this.order.finalPrice;
          this.qrData.des = `${res.prefix}${this.order.globalOrderId}`;
          
          this.qrImgSrc = `${this.qrCodeService.baseUrl}?acc=${this.qrData.accNo}&bank=${this.qrData.bank}&amount=${this.qrData.amount}&des=${this.qrData.des}`;
        }
      },
      error: (err) => console.log(err)
    });
  }

  gotoQuickOrder() {
    this.router.navigateByUrl('quick-order');
  }

  downloadQRCode() {
    this.qrCodeService.downloadQRCode(this.qrImgSrc, this.order.orderId);
  }

  getPaymentMethod(paymentMethod: string) {
    const paymentMethods: PaymentMethod[] = [
      { value: 'bank', label: 'Chuyển khoản ngân hàng' },
      { value: 'cash', label: 'Thanh toán bằng tiền mặt khi nhận hàng' },
    ];

    const payment = paymentMethods.find(pm => pm.value === paymentMethod);

    return payment ? payment.label : 'Khác';
  }

  gotoHome() {
    this.router.navigateByUrl('/');
  }

  ngOnDestroy() {
    this.notificationService.setPaymentNotify(false);
  }
}
