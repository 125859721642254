<section>
  <div class="container-fluid-lg container-fluid-md container-fluid-xs">
    <div class="row rounded-top d-flex" style="
        background: url('assets/images/home/bg.gif');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      ">
      <div class="col-5 p-4 text-white">
        <div class="row d-none d-lg-flex d-xl-flex">
          <h3>{{ this.title }}</h3>
        </div>
        <div class="d-flex d-none d-lg-flex d-xl-flex" style="gap: 20px">
          <h3 class="align-items-center d-flex">{{this.countingTitle}}</h3>
          <div class="border border-1 rounded-4 border-theme p-2 text-center"
            style="height: 40px; background-color: #dd0000">
            <p #hours></p>
          </div>
          <div class="align-items-center d-flex">:</div>
          <div class="border border-1 rounded-4 border-theme p-2 text-center"
            style="height: 40px; background-color: #dd0000">
            <p #minutes></p>
          </div>
          <div class="align-items-center d-flex">:</div>
          <div class="border border-1 rounded-4 border-theme p-2 text-center"
            style="height: 40px; background-color: #dd0000">
            <p #seconds></p>
          </div>
        </div>
      </div>
      <div class="col-7 ps-4 pt-4 pb-1 d-flex justify-content-right d-none d-lg-flex d-xl-flex justify-content-center"
        style="gap: 20px">
        <div class="d-inline align-self-end" *ngFor="let tab of datatabs">
          <div [class]="tab.class" *ngIf="tab.display == true">
            <button class="rounded-5 d-flex border-0 align-items-center {{
                active == tab.id ? 'button-active' : ''
              }}" (click)="changeActive(tab)" [id]="tab.id" style="width: 200px">
              <div class="text-center justify-content-center d-flex flex-column" style="width: 100%">
                {{ tab.titlebutton }}
                <span class="fw-bolder">{{ tab.name }}</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- MOBILE -->
    <div class="d-lg-none d-xl-none d-flex mobile">
      <div [class]="tab.class" class="w-100" *ngFor="let tab of datatabs">
        <div class="w-100" *ngIf="tab.display == true">
          <button 
            class="d-flex border-0 align-items-center w-100 {{ active == tab.id ? 'button-active' : '' }}" 
            (click)="changeActive(tab)" 
            [id]="tab.id"
          >
            <div class="text-center justify-content-center d-flex flex-column w-100">
              {{ tab.titlebutton }}
              <span class="fs-6 fw-bold">{{ tab.name }}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="product-section">
      <div class="row" style="background-color: #d00" *ngIf="products.length > 0 && type == 1">
        <div class="product-left-box">
          <div class="product-main-1 no-arrow">
            <owl-carousel-o *ngIf="products.length > 0" [options]="productSimilartSlider" #owlCar class="product-slide"
              (changed)="getPassedData($event)">
              <ng-container *ngFor="let id of offset">
                <ng-template carouselSlide [id]="id.toString()">
                  <div id="flashsale"
                    class="row row-cols-xxl-6 row-cols-lg-6 row-cols-md-2 row-cols-sm-2 row-cols-2 m-auto pt-4 pb-4 w-100">
                    <div *ngFor="let product of products">
                      <div class="slider-image h-100">
                        <app-product-box [product]="product" [class]="'product-box-3 mb-4'"
                          [style]="'horizontal'"></app-product-box>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </div>

    <div class="row pb-4" *ngIf="products.length > 0 && type == 2" style="background-color: #d00">
      <div [class]="gridClass">
        <ng-container *ngIf="skeletonLoader; else productContent">
          <app-skeleton-product-box *ngFor="let item of skeletonItems"></app-skeleton-product-box>
        </ng-container>
        <ng-template #productContent>
          <ng-container *ngFor="let product of viewproducts">
            <app-product-box [product]="product" [class]="'product-box-3 mb-2'"
              [style]="'horizontal'"></app-product-box>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>
</section>