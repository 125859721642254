<section class="service-section">
  <div>
    <div class="p-5 div-wrapper">
      <div class="py-4 bg-white" style="min-height: 600px;">
        <div class="text-theme justify-content-left d-lg-flex d-xl-flex d-md-flex d-sm-flex ps-4 pe-4 mobile">
          <h2 class="text-center me-2">
            <strong class="fw-bold">60 GIÂY</strong>
            <div style="background-color: #ffc803; border-radius: 5px; height: 5px;"></div>
          </h2>
          <h3><strong class="fw-bold">ĐỂ TẠO TÀI KHOẢN TẠI GIÁ THUỐC TỐT</strong></h3>
        </div>
        <div class="mt-4 ps-4 pe-4 div-tab">
          <div class="div-button d-none d-lg-flex d-xl-flex" style="gap: 20px">
            <div
              class="d-flex text-content-center pb-2"
              *ngFor="let tab of datatabs"
            >
              <button
                class="rounded-pill d-flex ps-4 pe-4 pt-2 pb-2 border-0 align-items-center {{
                  active == tab.id ? 'button-active' : ''
                }}"
                (click)="changeActive(tab)"
                [id]="tab.id"
              >
                <div
                  class="bg-theme text-white"
                  style="
                    border-radius: 50%;
                    width: 2.5rem;
                    height: 2.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <h2>{{ tab.number }}</h2>
                </div>
                <div class="text-center p-2">
                  <span>{{ tab.name }}</span>
                </div>
              </button>
            </div>
          </div>
          <!-- mobile -->
          <div class="d-lg-none d-xl-none d-flex align-items-center mobile">
            <button *ngFor="let tab of datatabs" 
              class="mobile-btn-text bg-white {{ active == tab.id ? 'button-active' : '' }}"
              (click)="changeActive(tab)"
              [id]="tab.id"
            >
              <span class="text-center fw-bold">{{ tab.name }}</span>
            </button>
          </div>

          <div class="row">
            <div>
              <section class="service-section p-0">
                <div class="content-wrapper d-lg-flex d-xl-flex d-md-block d-sm-block" style="font-size: 25px">
                  <div
                    class="col-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 justify-content-center d-flex flex-column div-content mobile-w-100"
                    [@wipeIn]="animationState"
                  >
                    <div
                      *ngIf="active === 'pin1'"
                      [innerHTML]="
                        datatabs[0].value
                      "
                    ></div>
                    <div *ngIf="active === 'pin1'" class="pt-4">
                      <button
                        class="btn bg-theme text-white rounded-pill"
                        (click)="handleButtonClick($event)"
                      >
                        <img src="assets/svg/edit 1.svg" />ĐĂNG KÝ MIỄN PHÍ NGAY
                      </button>
                    </div>

                    <div
                      *ngIf="active === 'pin2'"
                      [innerHTML]="
                        datatabs[1].value
                      "
                    ></div>

                    <div
                      *ngIf="active === 'pin3'"
                      [innerHTML]="
                        datatabs[2].value
                      "
                    ></div>
                  </div>

                  <div
                    class="col-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 d-flex justify-content-center mobile-w-100"
                  >
                    <img
                      src="assets/images/home/imagehomepage.png"
                      alt=""
                      style="width: 100%; height: 300px"
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-registry-modal-v2 #registryModal></app-registry-modal-v2>
  <app-login-modal #loginModal></app-login-modal>
</section>
