import { Component, Inject, Optional, PLATFORM_ID, TransferState } from '@angular/core';
import { ProductService } from '@/app/shared/services/product.service';
import { Product,ProductModel } from '@/app/shared/interface/product.interface';
import { CommonService } from '@/app/shared/services/common.service';
import { HomeConfigurationService } from '@/app/shared/services/homeconfiguration.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { FlashSaleService } from '@/app/shared/services/flashsale.service';
import { Flashsale } from '@/app/shared/interface/flashsale.interface';
import { Subscription } from 'rxjs';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { REQUEST } from '@/express.tokens';
import { AuthService, IS_AUTHENTICATED, TOKEN } from '@/app/shared/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  isAuthenticated: boolean = false;
  private routerEvent: Subscription;
  isBrowser: boolean = false;

  public flashsaleEvent: Flashsale;

  public home_banner: any = {};

  public banners: any[] = [];

  public feedbacks = [];

  public serviceshome = [
    {
      status: true,
      description: "Công nghệ tối ưu, thao tác dễ dàng",
      title: "ĐẶT HÀNG NHANH CHÓNG",
      image_url: 'assets/images/home/free-delivery.svg',
    },
    {
      status: true,
      description: "Hơn 10.000 sản phẩm",
      title: "ĐA DẠNG THUỐC & TPCN CHÍNH HÃNG",
      image_url: 'assets/images/home/Capa.svg',
    },
    {
      status: true,
      description: "Tích lũy lên tới 1%",
      title: "CHÍNH SÁCH THÀNH VIÊN",
      image_url: 'assets/images/home/customer-loyalty.svg',
    },
    {
      status: true,
      description: "Siêu tốc 4 Giờ",
      title: "GIAO HÀNG NHANH",
      image_url: 'assets/images/home/delivery.svg',
    }
  ];

  public dataupper = [
    {
      status: true,
      description: "Khách hàng thân thiết",
      title: "30.000+",
      image_url: 'assets/images/home/best-customer-experience.png',
    },
    {
      status: true,
      description: "Nhà sản xuất",
      title: "1000+",
      image_url: 'assets/images/home/medicine.png',
    },
    {
      status: true,
      description: "Chuyên gia tư vấn Chuyên Nghiệp",
      title: "500+",
      image_url: 'assets/images/home/team.svg',
    }
  ];

  public databot = [
    {
      status: true,
      description: "Chính sách thành viên hấp dẫn",
      title: "",
      image_url: 'assets/images/home/loyalty-program.png',
    },
    {
      status: true,
      description: "Chương trình khuyến mãi liên tục & hấp dẫn",
      title: "",
      image_url: 'assets/images/home/voucher.png',
    },
    {
      status: true,
      description: "Giải pháp công nghệ giúp tăng trải nghiệm khách hàng",
      title: "",
      image_url: 'assets/images/home/monitor.png',
    }
  ];
  
  constructor(private productService: ProductService,
    private commonService: CommonService,
    private homeConfigurationService: HomeConfigurationService, 
    public router: Router,
    private route: ActivatedRoute,
    private flashsaleservice: FlashSaleService,
    private cookieService: SsrCookieService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private transferState: TransferState,
    private authService: AuthService,
  ) {
    this.isAuthenticated = this.authService.isAuthenticated();
  }

  ngOnInit() {
    // const localStorage = this.document.defaultView?.localStorage;
    // const token = this.cookieService.get('token');
    // console.log('token: ', token);
    // this.isAuthenticated = token ? true : false;

    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;

      this.getHomeBanner();
      this.getVendorBanner();
      this.getCommentBanner();

      this.flashsaleservice.getFlashSaleEvents.subscribe({
        next: (data: Flashsale) => {
          this.flashsaleEvent = data;
        },
      });
    }
    
    this.commonService.authEvent.subscribe({
      next: (data: boolean) => {
        this.isAuthenticated = data;
      },
    });

    this.routerEvent = this.router.events.subscribe({
      next: (val: any)=> {
        if (val instanceof NavigationEnd){
          if(this.router.url.includes("/")){
            if (isPlatformBrowser(this.platformId)) {
              window.scrollTo({top: 0, behavior: 'smooth'});
            };
          }
        }
      }
    })
  }

  public products: Product[] = [];

  getRelatedProducts(searchTerm: string) {
    const body = {
      limit: 10,
      searchTerm: searchTerm
    }

    const token = this.transferState.get(TOKEN, '');

    if(token){
      this.productService.getProductsForMember(JSON.stringify(body)).subscribe({
        next: (res: ProductModel) => {
          if (res.products) {
            this.products = res.products;
          }
        }
      });
    }
    else {
      this.productService.getProductsForGuest(JSON.stringify(body)).subscribe({
        next: (res: ProductModel) => {
          if (res.products) {
            this.products = res.products;
          }
        }
      })
    }
    
  }

  getHomeBanner() {
    this.homeConfigurationService.getHomeBannerInfo().subscribe({
      next: (res: any) => {
        if (res) {
          this.home_banner = res;
          if (!this.isAuthenticated) {
            this.home_banner.main_banner = this.home_banner.main_banner.map((item: any) => {
              item.redirect_link.link = '/auth/login';
              return item;
            });
            this.home_banner.sub_banner_1.redirect_link.link = '/auth/login';
            this.home_banner.sub_banner_2.redirect_link.link = '/auth/login';
          }
        }
      }
    });
  }

  getVendorBanner() {
    this.homeConfigurationService.getVendorBannerInfo().subscribe({
      next: (res: any) => {
        if (res) {
          this.banners = res;
          if (!this.isAuthenticated) {
            this.banners = this.banners.map((item: any) => {
              item.redirect_link.link = '/auth/login';
              return item;
            });
          }
        }
      }
    });
  }

  getCommentBanner() {
    this.homeConfigurationService.getCommentBannerInfo().subscribe({
      next: (res: any) => {
        if (res) {
          this.feedbacks = res;
        }
      }
    });
  }

  ngOnDestroy(){
    this.routerEvent?.unsubscribe();
  }
}
