<header class="header-2" [class.active]="stick && sticky" style="background-color: var(--theme-color);" *ngIf="data">
  <!--  <app-topbar [data]="data" *ngIf="data?.header?.page_top_bar_enable"></app-topbar>-->
  <div class="top-nav top-header sticky-header sticky-header-3"
    style="display: flex;justify-content: center;background-color: var(--theme-color);">
    <div class="container-fluid-lg container-fluid-md container-fluid-xs" style="max-width: 1400px; width: 1400px;">
      <div class="row" style="width: 100%;">
        <div class="col-12">
          <div class="navbar-top d-flex">
            <app-button [class]="'navbar-toggler d-xl-none d-flex navbar-menu-button'" [type]="'button'"
              [id]="'toggle_menu'" (click)="toggle()">
              <span class="navbar-toggler-icon">
                <i class="ri-align-left f-w-600 me-3"></i>
              </span>
            </app-button>
            <app-logo [textClass]="'txt-theme f-w-600'" [data]="data" [logo]="data.logo.header_logo_url"></app-logo>
            <div class="middle-box flex-grow-1 px-5">
              <div class=" d-flex flex-column w-100">
                <div class="center-box mb-2 w-100">
                  <div class="searchbar-box w-100">
                    <app-search-bar [style]="'standard'" class="w-100"></app-search-bar>
                  </div>
                </div>
                <div class="center-box d-flex justify-content-start">
                  <div class="searchbar-box px-3" *ngFor="let tag of data?.header?.pin_tags">
                    <a href="{{tag.url}}" class="search-link"><strong>{{ tag.name }}</strong></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="rightside-menu" style="padding-left: 30px; padding-bottom: 3px;">
              <li *ngIf="!isAuthenticated">
                <app-button [class]="
                  'btn bg-white text-theme btn-sm fw-bold mt-lg-0 ms-auto btn-border-radus-max'
                " [type]="'button'" [spinner]="false" [id]="'btn_login'" (click)="LoginModal.openModal()">
                  Đăng nhập
                </app-button>
              </li>
              <li class="ps-2" *ngIf="!isAuthenticated">
                <app-button [class]="'btn text-white btn-sm fw-bold mt-lg-0 ms-auto'" [type]="'button'"
                  [spinner]="false" [id]="'add_user'" (click)="RegistryModal.openModal()">
                  Đăng ký
                </app-button>
              </li>
            </div>
            <div class="rightside-menu align-self-start">
              <div class="option-list">
                <ul>
                  <!-- <li>
                      <app-search-box [style]="'basic'"></app-search-box>
                    </li> -->

                  <!-- <li *ngIf="isAuthenticated">
                      <app-nav-noti  [style]="'standard'" [isAuthenticated]="isAuthenticated"></app-nav-noti>
                    </li> -->
                  <li *ngIf="isAuthenticated">
                    <app-header-cart [style]="'classic'"></app-header-cart>
                  </li>
                  <li *ngIf="isAuthenticated">
                    <app-favorite-product [style]="'standard'"
                      [isAuthenticated]="isAuthenticated"></app-favorite-product>
                  </li>
                  <li *ngIf="isAuthenticated" class="ms-3">
                    <app-my-account [style]="'standard'" [isAuthenticated]="isAuthenticated"></app-my-account>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid-lg bg-white">
    <div class="row">
      <div class="col-12">
        <div class="main-nav justify-content-center">
          <!-- <div class="header-nav-left">
              <app-header-categories [data]="data"></app-header-categories>
            </div> -->
          <div class="main-nav navbar navbar-expand-xl navbar-light navbar-sticky py-1">
            <div class="offcanvas offcanvas-collapse order-xl-2" [class.show]="active" id="primaryMenu">
              <div class="offcanvas-header navbar-shadow">
                <h5>{{ "MENU" | translate }}</h5>
                <app-button [type]="'button'" [class]="'btn-close lead'" [id]="'toggle_menu_btn'" [spinner]="false"
                  (click)="toggle(false)">
                  <i class="ri-close-fill"></i>
                </app-button>
              </div>
              <div class="offcanvas-body">
                <app-menu (active)="toggle($event)" [spinner]="false"></app-menu>
              </div>
            </div>
            <div class="offcanvas-backdrop fade" *ngIf="active == true" [class.show]="active" (click)="toggle(false)">
            </div>
          </div>
          <div class="main-nav navbar navbar-expand-xl navbar-light navbar-sticky py-1 d-lg-none d-xl-none">
            <div class="dashboard-left-sidebar bg-white border shadow-none" [class.show]="open">
              <div class="user-dashboard-section section-b-space" [class.show]="open"
                style="position: absolute; z-index: 7;">
                <div class="dashboard-left-sidebar bg-white border shadow-none" [class.show]="open">
                  <app-sidebar (menu)="openMenu($event)"></app-sidebar>
                </div>
              </div>
              <div class="offcanvas-backdrop fade" *ngIf="open == true" [class.show]="open" (click)="openMenu(false)">
              </div>
            </div>
          </div>
          <!-- <div class="right-nav">
              <app-call [style]="'standard'" [data]="data"></app-call>
              <app-deal [style]="'standard'" [data]="data"></app-deal>
            </div> -->
        </div>
      </div>
    </div>
  </div>
</header>
<app-mobile-menu></app-mobile-menu>

<app-login-modal #loginModal (openRegistryModal)="openRegistryModal($event)"
  (openForgotPasswordModal)="openForgotPasswordModal($event)"></app-login-modal>
<!-- <app-registry-modal #registryModal (openLoginModal)="openLoginModal($event)"></app-registry-modal> -->
<app-registry-modal-v2 #registryModal (openLoginModal)="openLoginModal($event)"></app-registry-modal-v2>
<app-forgot-password-modal #forgotPasswordModal
  (openRegistryModal)="openRegistryModal($event)"></app-forgot-password-modal>