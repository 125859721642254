import {
  Component,
  TemplateRef,
  ViewChild,
  PLATFORM_ID,
  Inject,
  Output,
  EventEmitter
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { AuthService } from '@/app/shared/services/auth.service';
import { CommonService } from '@/app/shared/services/common.service';

import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrl: './login-modal.component.scss',
})
export class LoginModalComponent {
  @Output() openRegistryModal: EventEmitter<any> = new EventEmitter();
  @Output() openForgotPasswordModal: EventEmitter<any> = new EventEmitter();
  @ViewChild('loginModal', { static: false }) LoginModal: TemplateRef<string>;

  public form: FormGroup;
  public closeResult: string;
  public modalOpen: boolean = false;

  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private commonService: CommonService,
    private toast: ToastrService,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    })
  }


  async openModal() {
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalOpen = true;
      this.modalService
        .open(this.LoginModal, {
          ariaLabelledBy: 'address-add-Modal',
          centered: true,
          windowClass: 'theme-modal modal-login',
        })
        .result.then(
          (result) => {
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  private getDismissReason(reason: ModalDismissReasons): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.authService.login(JSON.stringify(this.form.value))
    }
  }

  handleButtonClick(event: Event) {
    this.modalService.dismissAll();
    this.openRegistryModal.emit(true);

  }

  handleForgotPasswordModal(event: Event) {
    this.modalService.dismissAll();
    this.openForgotPasswordModal.emit(true);

  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.commonService.setShowLoginPopup(false);
      this.modalService.dismissAll();
    }
  }
}
