<section>
  <div class="container-fluid-lg container-fluid-md container-fluid-xs mobile px-0">
    <div class="container">
      <div class="row mt-4 div-row" style="gap: 20px">
        <div class="col" *ngFor="let category of complPosts" style="background-color: #fff">
          <div class="row pb-4 pt-4">
            <div class="col-9 ps-4 mobile-w-100" style="display: flex; align-items: center">
              <h2 class="d-none d-lg-block d-xl-block">
                <span style="font-weight: 600;">{{ category.name }}</span>
              </h2>
              <h2 class="d-lg-none d-xl-none text-theme">
                <span style="font-weight: 600;">{{ category.name }}</span>
                <div class="d-lg-none d-xl-none" style="background-color: #ffc803; border-radius: 5px; height: 5px;"></div>
              </h2>
            </div>
            <div class="col-3 d-flex justify-content-end d-none d-lg-flex d-xl-flex">
              <a [routerLink]="'/blog'" class="border border-1 py-1 px-3 fs-6"
                style="color: var(--theme-color); background: none; border-color: #247636 !important; border-radius: 13px;">
                Xem thêm
                <i class="ri-arrow-right-s-line fs-6"></i>
              </a>
            </div>
          </div>
          <div class="row pb-4 d-xl-flex d-lg-flex div-row">
            <div class="col-4 row-w-100" *ngFor="let post of category.dataDetail">
              <div class="col">
                <a class="text-dark" [routerLink]="post.slug">
                  <img [src]="post.postThumbnailUrl" [title]="post.postTitle" alt="" style="width: 100%" />
                  <p class="pt-2">{{ post.postTitle }}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
