import { Component, Inject, PLATFORM_ID, TransferState } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MobileMenu } from '@/app/shared/interface/menu.interface';
import { CommonService } from '@/app/shared/services/common.service';
import { Cart } from '../../interface/cart.interface';
import { isPlatformBrowser } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { TOKEN } from '../../services/auth.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent {

  public showSearchBar: boolean = false;
  public cart: Cart = new Cart();

  selectedMenu: MobileMenu;
  public menuItem: MobileMenu[] = [
    {
      id: 1,
      active: true,
      title: 'Trang Chủ',
      icon: 'ri-home-2',
      path: ''
    },
    {
      id: 2,
      active: false,
      title: 'Sản Phẩm',
      icon: 'ri-apps-line js',
      path: '/product'
    },
    {
      id: 3,
      active: false,
      title: 'Tìm Kiếm',
      icon: 'ri-search-2',
      path: '/quick-order'
    },
    {
      id: 4,
      active: false,
      title: 'Giỏ Hàng',
      icon: 'fly-cate ri-shopping-bag',
      path: '/cart'
    },
    {
      id: 5,
      active: false,
      title: 'Tài Khoản',
      icon: 'fly-cate ri-account-circle'
    }
  ]

  public cartTotal: number = 0;

  constructor(
    private router: Router, 
    private commonService: CommonService, 
    private cookieService: SsrCookieService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object,
  ){
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.menuItem?.forEach((menu: MobileMenu) => {
          menu.active = false;
          if((event.url.split("?")[0].toString()) === menu.path){
            menu.active = true;
            this.selectedMenu = menu;
          }
        })
      }
    })

  }
  
  ngOnInit() {
    this.commonService.getCart.subscribe({
      next: (res: any) => {
        if (res) {
          this.cart = res;
          this.cartTotal = this.cart.items.reduce((current, next) => { return current + next.quantity }, 0);
        }
      }
    })
    this.commonService.getShowLoginPopup.subscribe({
      next: (data: boolean) => {
        if (data) {
          this.activeMenu(this.selectedMenu);
        }
      },
    })
  }

  activeMenu(menu: MobileMenu){
    if (isPlatformBrowser(this.platformId) && menu) {
      this.menuItem.forEach(item => {
        this.menuItem.includes(menu)
        item.active  = false;
      })
      menu.active = !menu?.active

      if (menu.id == 3) {
        this.showSearchBar = true;
      }

      if (menu.id == 5) {
        // const token = this.cookieService.get('token');

        const token = this.transferState.get(TOKEN, '');

        if (token) {
          this.commonService.setOpenMobileMenu(true);
        }
        else {
          this.commonService.setShowLoginPopup(true);
        }
      }
    }
  }
}
