import { Component, Inject, Input, Optional, PLATFORM_ID, TransferState, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as feather from 'feather-icons';
import { User } from '@/app/shared/interface/user.interface';
import { AccountUser } from '@/app/shared/interface/account.interface';
import { ConfirmationModalComponent } from '@/app/shared/widgets/modal/confirmation-modal/confirmation-modal.component';
import { AuthService, IS_AUTHENTICATED } from '@/app/shared/services/auth.service';
import { CommonService } from '@/app/shared/services/common.service';
import { AccountService } from '@/app/shared/services/account.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { isPlatformServer } from '@angular/common';
import { REQUEST } from '@/express.tokens';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent {
  @Input() style: string = 'basic';

  @Input() isAuthenticated: boolean = false;
  user: any;
  username: any = '';

  @ViewChild('confirmationModal') ConfirmationModal: ConfirmationModalComponent;

  constructor(
    private authService: AuthService,
    private commonService: CommonService,
    private router: Router,
    private accountService: AccountService,
    private transferState: TransferState
  ) {
    this.isAuthenticated = this.authService.isAuthenticated();
    this.setDisplayName();
  }

  ngOnInit() {
    this.commonService.authEvent.subscribe({
      next: (data: boolean) => {
        this.isAuthenticated = data;
        this.setDisplayName();
      },
    });
  }

  setDisplayName() {
    if (this.isAuthenticated) {
      this.accountService.getUserData.subscribe({
        next: (res: User) => {
          if (res && res.fullName) {
            this.user = res;
            let displayName = res.fullName.split(' ');
            this.username = displayName[displayName.length - 1];
          }
        },
      });
    }
    
  }

  logout(value: boolean) {
    if (value) {
      this.authService.logout();
      this.router.navigateByUrl('/', { replaceUrl: true });
    }
  }
}
