<div class="close-button d-flex d-lg-none" style="z-index: 5;">
  <app-button
    [class]="'close-sidebar'"
    [style]="'button'"
    [id]="'close_sidebar_btn'"
    [spinner]="false"
    (click)="openMenu(false)"
  >
    <i class="ri-close-fill"></i>
  </app-button>
</div>
<div class="profile-box pb-4">
  <!-- <div class="cover-image">
        <img src="assets/images/inner-page/cover-img.jpg" class="img-fluid" alt="cover-img">
    </div> -->
  <div class="profile-contain">
    <div class="profile-image text-start d-flex gap-3 mt-4">
      <div class="position-relative">
        <img
          [src]="avatarUrl"
          class="update_img"
          alt="profile"
          *ngIf="
            avatarUrl && avatarUrl.length > 0;
            else placeholder
          "
        />
        <ng-template #placeholder>
          <div class="user-round">
            <img
              class="sidebar-avatar"
              src="assets/svg/user-avatar.svg"
              alt="Avatar"
            />
            <!-- <h4>{{ user?.name?.charAt(0)?.toString()?.toUpperCase() }}</h4> -->
          </div>
        </ng-template>
      </div>
      <div class="profile-name border-0 mt-0 pb-0">
        <h6 class="text-content">Tài khoản của</h6>
        <h3 class="text-center">{{ fullName }}</h3>
      </div>
    </div>
  </div>
</div>
<ul
  class="nav nav-pills user-nav-pills gap-3"
  id="pills-tab"
  role="tablist"
  *ngIf="sideBar"
>
  <li class="nav-item" role="presentation" *ngFor="let sb of sideBar">
    <a
      class="nav-link d-flex gap-3"
      [class]="active == sb.name ? 'active' : ''"
      [routerLink]="[ '/account/' + sb.path ]"
      (click)="active = sb.name; openMenu(false)"
      *ngIf="sb.display"
    >
      <img
        [src]="'assets/svg/' + sb.icon"
        [class]="active == sb.name ? 'active' : ''"
        [alt]="sb.name"
      />
      {{ sb.name }}
    </a>
  </li>
  <li class="nav-item logout-cls" role="presentation">
    <a
      href="javascript:void(0)"
      class="btn btn-logout d-flex gap-2 justify-content-start px-4"
      (click)="confirmationModal.openModal()"
      (click)="openMenu(false)"
    >
      <img src="assets/svg/logout 1.svg" alt="Logout" /> Đăng xuất
    </a>
  </li>
</ul>
<app-confirmation-modal
  #confirmationModal
  [message]="'Bạn chắc chắn muốn đăng xuất khỏi hệ thống ?'"
  (confirmed)="logout($event)"
>
</app-confirmation-modal>
