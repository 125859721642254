import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { Observable } from "rxjs";
import { PagingResponseModel } from "../interface/pagination.interface";
import { RetriveUserModel } from "../interface/product.interface";
import { Tag } from "../interface/tag.interface";
import { BankInfo } from "../interface/qrcode.interface";

@Injectable({
    providedIn: "root",
})
export class QRCodeService {

    public baseUrl = 'https://qr.sepay.vn/img';

    constructor(private http: HttpClient) { }

    getBankInfo() {
        return this.http.get<BankInfo>(`${environment.baseURL}bank-info`);
    }


    downloadQRCode(qrImgSrc: string, fileName: string) {
        fetch(qrImgSrc)
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = fileName + '.png';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

}
