import { Component } from '@angular/core';
import { Input } from '@angular/core';
import * as data from '@/app//shared/data/owl-carousel';
@Component({
  selector: 'app-brand-home',
  templateUrl: './brand-home.component.html',
  styleUrl: './brand-home.component.scss'
})
export class BrandHomeComponent {

  @Input() style: string = 'horizontal';
  @Input() class: string | null;
  @Input() contentClass: string;
  @Input() banners: any;
  
  public bannerSlider = data.customOptions4Item;
}
