import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-partner-logo',
  templateUrl: './partner-logo.component.html',
  styleUrl: './partner-logo.component.scss'
})
export class PartnerLogoComponent {
  public data = {
    "header": "ĐỐI TÁC",
    "header2": " CỦA GIÁ THUỐC TỐT",
    "url": "assets/images/home/group-66.svg"
  }
}
