import { Voucher } from '@/app/shared/interface/voucher.interface';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-voucher-detail-modal',
  templateUrl: './voucher-detail-modal.component.html',
  styleUrl: './voucher-detail-modal.component.scss'
})
export class VoucherDetailModalComponent {

  @ViewChild("detailModal", { static: false }) detailModal: TemplateRef<any>;

  public modalOpen: boolean = false;

  @Input() selectedVoucher: Voucher;

  constructor(
    private modalService: NgbModal,
    private toastService: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  async openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalOpen = true;
      this.modalService.open(this.detailModal, {
        ariaLabelledBy: 'Confirmation-Modal',
        centered: true,
        windowClass: 'theme-modal text-center',
        size:'lg'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.modalOpen = false;
      });
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  copyToClipboard(voucher: Voucher) {
    if(isPlatformBrowser(this.platformId)) {
      navigator.clipboard.writeText(voucher.code);
    }
    this.toastService.success("Sao chép mã giảm giá thành công!", "" , {positionClass: "toast-top-right"});
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
}
