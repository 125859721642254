import { Component, Input, TransferState } from '@angular/core';

import { FooterLogo, Option } from '@/app/shared/interface/common.interface';
import { HomeConfigurationService } from '@/app/shared/services/homeconfiguration.service';
import { DEFAULT_VERSION, CURRENT_VERSION } from '@/app/shared/services/auth.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

  @Input() data!: Option | null;
  @Input() footer!: FooterLogo;
  
  currentVersion: string;
  
  constructor(
    private footerService: HomeConfigurationService,
    private transferState: TransferState
  ) { 
    this.currentVersion = this.transferState.get(CURRENT_VERSION, DEFAULT_VERSION);
  }


  public active: { [key: string]: boolean } = {
    categories: false,
    useful_link: false,
    help_center: false,
  };

  toggle(value: string){
    this.active[value] = !this.active[value];
  }

}
