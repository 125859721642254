<section class="service-section bg-white">
    <div class="container-fluid-lg container-fluid-md container-fluid-xs">
      <div class="text-theme justify-content-left d-flex">
        <h2 class="text-center me-2">
          <strong class="fw-bold">{{data.header}}</strong>
          <div style="background-color: #ffc803; border-radius: 5px; height: 5px;"></div>
        </h2>
        <h3><strong>{{data.header2}}</strong></h3>
      </div>
    </div>
    <div class="">
        <app-partner-logo-svg></app-partner-logo-svg>
    </div>
  </section>
  