<!-- Banner Horizontal Style-->
<div class=" container-fluid-lg container-fluid-md container-fluid-xs" *ngIf="banners?.length && style == 'horizontal'">
  <div class="banner-slider">
    <span class="text-theme fs-5 d-flex py-3">
      <strong class="d-block fw-bold" style="width: fit-content; font-size: 40px;">ƯU ĐÃI
        <div>
          <div class="rounded-5" style="background-color: #ffc803; height: 5px"></div>
        </div>
      </strong>
      <span class="text-theme d-flex p-1" style="width: fit-content; font-size: 35px;">TỪ HÃNG
        <div class="d-flex">
          <div class="col-5 rounded-5" style=" height: 5px"></div>
          <div class="col-7 rounded-5" style="height: 5px"></div>
        </div>
      </span>
    </span>
    <owl-carousel-o [options]="bannerSlider" class="d-none d-lg-block d-xl-block">
      <ng-container carouselSlide *ngFor="let banner of banners">
        <ng-container *ngIf="banner?.status">
          <ng-template carouselSlide>
            <a [href]="banner.redirect_link.link" target="_blank">
              <div class="banner-contain">
                <img [class]="'banner-contain'" [src]="banner.image_url" />
              </div>
            </a>
          </ng-template>
        </ng-container>
      </ng-container>
    </owl-carousel-o>
    <!-- mobile -->
    <div class="d-lg-none d-xl-none g-sm-2 row row-cols-2 row-cols-lg-2 row-cols-md-2 row-cols-xxl-2 p-2">
      <div *ngFor="let banner of banners" class="p-1">
        <div *ngIf="banner.redirect_link.link">
          <a [href]="banner.redirect_link.link">
            <div class="banner-contain">
              <app-image-link [class]="'banner-contain'" [image]="banner" [bgImage]="false"></app-image-link>
            </div>
          </a>
        </div>
        <div *ngIf="!banner.redirect_link.link">
          <div class="banner-contain">
            <app-image-link [class]="'banner-contain'" [image]="banner" [bgImage]="false"></app-image-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Banner Vertical Style-->
<div [class]="class" *ngIf="banners?.image_url && style == 'vertical'">
  <div class="home-contain hover-effect">
    <app-image-link [class]="'banner-contain'" [image]="banners" [bgImage]="false"></app-image-link>
  </div>
</div>
<!-- Banner Full Width Style-->
<div *ngIf="banners?.image_url && style == 'full_width'">
  <app-image-link [class]="'banner-contain'" [image]="banners" [bgImage]="false"></app-image-link>
</div>