<!-- My Account Basic Style -->
<div *ngIf="style == 'basic'">
  <div class="delivery-login-box">
    <div class="delivery-icon">
      <i class="ri-user-line" *ngIf="!isAuthenticated; else profile"></i>
      <ng-template #profile>
        <div class="user-box">
          <ng-template #placeholder>
            <h3>
              {{username}}
            </h3>
          </ng-template>
        </div>
      </ng-template>
    </div>
    <div class="delivery-detail">
      <h6>
        {{ "hi" | translate }},
        {{ isAuthenticated ? user : ("user" | translate) }}
      </h6>
      <h5>Thông tin người dùng</h5>
    </div>
  </div>
  <div class="onhover-div onhover-div-login">
    <ul class="user-box-name">
      <li class="product-box-contain" *ngIf="isAuthenticated">
        <a [routerLink]="['/account']"><i class="ri-user-line me-2"></i> Thông tin người dùng</a>
      </li>
      <li class="product-box-contain" *ngIf="isAuthenticated">
        <div style="display: flex">
          <a [routerLink]="['/account/my-order']"><i class="ri-shopping-cart-2-line me-2" ></i> Đơn hàng của tôi</a>
        </div>
          </li>
      <li class="product-box-contain" *ngIf="isAuthenticated">
        <a href="javascript:void(0)" (click)="confirmationModal.openModal()"><i class="ri-logout-box-r-line me-2"></i>
          Đăng xuất</a>
      </li>
    </ul>
  </div>
</div>
<!-- My Account Classic Style -->
<div class="onhover-dropdown h-100 d-flex align-items-center">
  <!--  white border for this div-->
  <div class="d-inline-block align-middle"
    style="border: 2.5px solid white; border-radius: 25px;">
    <div class="d-inline-block align-middle p-1">
      <a href="javascript:void(0)" class="user-box" *ngIf="style == 'classic'">
        <span class="header-icon">
          <i class="ri-user-line"></i>
        </span>
        <div class="user-name">
          <h6 class="text-content">Thông tin người dùng</h6>
          <h4 class="mt-1">
            {{ isAuthenticated ? user : "User" }}
          </h4>
        </div>
      </a>
      <a href="javascript:void(0)" class="header-icon search-icon border-0" *ngIf="style == 'standard'"
        style="width: 1.75rem; height: 1.75rem;">
        <img src="/assets/svg/User.svg">
      </a>
    </div>
    <div class="d-inline-block text-white p-1" style="text-transform: uppercase; font-size: 15px">
      <div>
        
        <span *ngIf="username.length > 7" class="text-white">
          {{ username.split(' ').pop().length > 7 ? (username.split(' ').pop() | slice: 0:7) : (username | slice: 0:7) }}...
        </span>
        <span *ngIf="username.length < 8" class="text-white">{{username}}</span>
        <span class="triangle-down"></span>
      </div>

    </div>
  </div>

  <div class="onhover-div onhover-div-login">
    <ul class="user-box-name">
      <li class="product-box-contain" *ngIf="isAuthenticated">
        <a [routerLink]="['/account']"><i class="ri-user-line me-2"></i> Thông tin người dùng</a>
      </li>
          <li class="product-box-contain" *ngIf="isAuthenticated">
        <div style="display: flex">
          <a [routerLink]="['/account/my-order']"><i class="ri-shopping-cart-2-line me-2" ></i> Đơn hàng của tôi</a>
        </div>
          </li>
      <li class="product-box-contain" *ngIf="isAuthenticated">
        <a href="javascript:void(0)" (click)="confirmationModal.openModal()"><i class="ri-logout-box-r-line me-2"></i>
          Đăng xuất</a>
      </li>
    </ul>
  </div>
</div>

<app-confirmation-modal #confirmationModal [title]="'Xác nhận'"
  [message]="'Bạn chắc chắn muốn đăng xuất khỏi hệ thống ?'" (confirmed)="logout($event)">
</app-confirmation-modal>