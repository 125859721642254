<ng-container *ngIf="menuItems.length">
    <div class="post-muc-luc my-3 rounded rounded-3">
        <div class="p-3">
            <div class="text-uppercase fw-bold mb-3">
                <i class="ri-menu-2-line"></i>&nbsp;
                Mục lục bài viết
            </div>
            <div class="menu-item">
                <ul>
                    <ng-container *ngTemplateOutlet="recursiveMenu; context: { $implicit: menuItems }"></ng-container>
                    <ng-template #recursiveMenu let-menus>
                        <ng-container>
                            <li *ngFor="let item of menus">
                                <a href="{{item.path}}">{{item.title}}</a>
                                <ul *ngIf="item.children">
                                    <ng-container
                                        *ngTemplateOutlet=" recursiveMenu; context: { $implicit: item.children }"></ng-container>
                                </ul>
                            </li>
                        </ng-container>
                    </ng-template>
                </ul>
            </div>
        </div>
    </div>
</ng-container>