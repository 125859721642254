import { Component, Input } from '@angular/core';
import * as data from "@/app/shared/data/owl-carousel";
import {customOptions1Item} from "@/app/shared/data/owl-carousel";

@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrl: './home-banner.component.scss'
})
export class HomeBannerComponent {

  @Input() data: any;
  @Input() banners: any;
  numbers: number[] = Array.from({ length: 2 }, (_, index) => index + 1);
  public bannerSlider = data.customOptions1Item;
}
