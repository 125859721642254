import { Component, EventEmitter, Input, Output, TransferState } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { CartService } from '@/app/shared/services/cart.service';
import { CommonService } from '@/app/shared/services/common.service';
import { CartItem } from '@/app/shared/interface/cart.interface';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription, firstValueFrom } from 'rxjs';
import { TOKEN } from '../../services/auth.service';
import { isInteger } from '@ng-bootstrap/ng-bootstrap/util/util';

@Component({
  selector: 'app-counter-gift',
  templateUrl: './counter-gift.component.html',
  styleUrl: './counter-gift.component.scss',
})
export class CounterGiftComponent {
  @Input() cartItem: CartItem;

  @Output() userGiftPoint = new EventEmitter<number>();

  public disablebutton: boolean = false;
  public product: any;
  public quantity: string = '0';

  private updateQuantitySubject = new Subject<any>();
  private updateQuantitySubscription: Subscription;
  private readonly debounceTimeMs = 500;

  constructor(
    private cartService: CartService,
    private commonService: CommonService,
    private toastService: ToastrService,
    private cookieService: SsrCookieService,
    private transferState: TransferState
  ) {}

  ngOnInit() {
    this.quantity = this.cartItem.quantity.toString();

    this.updateQuantitySubscription = this.updateQuantitySubject
      .pipe(debounceTime(this.debounceTimeMs))
      .subscribe(({ item, value, event }) =>
        this.updateQuantity(item, value, event)
      );
  }
  ngOnDestroy() {
    this.updateQuantitySubscription.unsubscribe();
  }

  onQuantityChange(item: any, value?: number, event?: any) {
    if (this.disablebutton) {
      return;
    }

    if (item.quantity + value < 0) {
      return;
    }

    if(!Number.isInteger(item.quantity)){
      item.quantity = Math.floor(item.quantity)
    }

    // Handle decimal input validation
    if (event && !Number.isInteger(Number(event.target.value))) {
      item.quantity = Math.floor(item.quantity)
    }

    let newQuantity = Math.max(0, item.quantity + (value || 0));
    item.quantity = newQuantity;
    this.updateQuantitySubject.next({ item, value, event });
  }

  async updateQuantity(item: any, value?: number, event?: any) {
    // const token = this.cookieService.get('token');
    this.disablebutton = true;
    const token = this.transferState.get(TOKEN, '');

    if (!token) {
      this.toastService.error('Xin vui lòng đăng nhập!', '', {
        positionClass: 'toast-top-right',
      });
      return;
    }

    if (item.quantity < 0) {
      this.toastService.error('Vui lòng nhập số lượng hợp lệ!', '', {
        positionClass: 'toast-top-right',
      });
      return;
    }

    let body = {
      productId: item.productID,
      quantity: Number(item.quantity),
    };
    if (event) {
      body.quantity = Number(event.target.value);
    }
    if (value == null) {
      body.quantity = 0;
    }
    
    const res: any = await firstValueFrom(
      this.cartService.updateCartGift(JSON.stringify(body))
    );

    if (res && res.data && res.statusCode == 200) {
      this.quantity = item.quantity.toString();
      item.quantity = body.quantity;
      this.commonService.setCart(res.data);
      if (item.quantity == 0) {
        this.commonService.setCartItem(item);
      }

      this.userGiftPoint.emit(res.data.totalPoint);
      
      this.toastService.success(res.message, '', {
        positionClass: 'toast-top-right',
      });
    }
    else {
      item.quantity = Number(this.quantity);
      this.toastService.error(res.message, '', {
        positionClass: 'toast-top-right',
      });
    }
    this.disablebutton = false;
  }
}
