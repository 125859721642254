import { PostModel } from '@/app/shared/interface/blog.interface';
import { Breadcrumb } from '@/app/shared/interface/breadcrumb';
import { BlogService } from '@/app/shared/services/blog.service';
import { SeoService } from '@/app/shared/services/seo.service';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { title } from 'process';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrl: './blog.component.scss'
})
export class BlogComponent {

  slug: string;
  public postDetail: PostModel;

  private style?: HTMLLinkElement;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private blogService: BlogService,
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2,
    private sanitizer: DomSanitizer,
    private seoService: SeoService
  ) {
    // this.route.params.subscribe(params => {
    //   if (params["slug"]) {
    //     this.slug = params["slug"];
    //   }
    //   this.blogService.getBlogBySlug(this.slug).subscribe({
    //     next: (p: PostModel) => {
    //       if (!p) {
    //         this.router.navigate(['/error404']);
    //       } else {
    //         this.postDetail = p;
    //       }
    //     },
    //     error: (err: any) => {
    //       console.error(err);
    //       this.router.navigate(['/error404']);
    //     }
    //   });
    // });
  }

  ngOnInit() {
    this.getStyle();
    this.route.data.subscribe({
      next: (data) => {
        if (data && data['blog']) {
          this.postDetail = data['blog'];
          this.seoService.generateTags({
            title: this.postDetail.postTitle,
            metaTitle: this.postDetail.postTitle,
            description: this.postDetail.postDescription,
            image: this.postDetail.postThumbnailUrl,
          })
        }
        else {
          this.router.navigate(['/error404']);
        }
      },
      error: (err: any) => {
        console.error(err);
        this.router.navigate(['/error404']);
      }
    });
  }

  getStyle() {
    this.blogService.getStylePost().subscribe({
      next: (res: string) => {
        this.loadStyle(res);
      },
      error: (err: any) => {
        console.error(err);
      }
    })
  }

  loadStyle(cssCode: string = '') {
    let safeCssCode = this.sanitizer.sanitize(SecurityContext.STYLE, cssCode);
    if (!safeCssCode) {
      return;
    }

    // Create a link element via Angular's renderer to avoid SSR troubles
    this.style = this.renderer2.createElement('style') as HTMLLinkElement;
    this.style.innerHTML = safeCssCode;
    // Add the style to the head section
    this.renderer2.appendChild(this.document.head, this.style);
  }

  ngOnDestroy() {
    if (this.style) {
      this.renderer2.removeChild(this.document.head, this.style);
    }
  }
}
