import { RatingModel } from '@/app/shared/interface/blog.interface';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-blog-rating',
  templateUrl: './blog-rating.component.html',
  styleUrl: './blog-rating.component.scss'
})
export class BlogRatingComponent {
  @Input() rating?: RatingModel;

  fillStar: number = 0;
  halfStar: number = 0;
  lineStar: number = 0;

  fillStarArray: number[] = [];
  halfStarArray: number[] = [];
  lineStarArray: number[] = [];

  private maxStar = 5;
  ngOnInit() {
    if (this.rating) {
      this.rating.rating = this.rating.rating > this.maxStar ? this.maxStar : this.rating.rating;

      this.fillStar = Math.floor(this.rating.rating);
      this.halfStar = Math.round(this.rating.rating) === this.rating.rating ? 0 : 1;
      this.lineStar = this.maxStar - this.fillStar - this.halfStar;
    }
    this.fillStarArray = Array(this.fillStar).fill(0);
    this.halfStarArray = Array(this.halfStar).fill(0);
    this.lineStarArray = Array(this.lineStar).fill(0);
  }
}
