<ng-template #exchangeGiftModal let-modal>
  <div class="modal-content">
    <div class="modal-header justify-content-center">
      <h4 class="fw-bolder">Đổi lá xanh nhận quà</h4>
      <img
        src="/assets/svg/register/Cancel.svg"
        id="login_modal_close_btn"
        (click)="close()"
        class="position-absolute"
        style="right: 0.25rem;"
      />
    </div>
    <div class="modal-body px-2">
      <div>
        <div class="div-border">
          <div class="d-flex gap-2 align-items-start">
            <img src="assets/svg/leaf_green.svg" alt="leaf" class="img-fluid" />
            <div class="d-flex align-items-center">
              <div class="d-grid text-start" *ngIf="cart">
                <h2 class="theme-color">{{cart.totalPoint ? cart.totalPoint : 0}}</h2>
                <span style="color: #717171">Số lá xanh khả dụng</span>
              </div>
            </div>
          </div>
          <div class="text-start d-grid" [innerHTML]="giftInfo?.giftDescriptionMini">
          </div>
        </div>
        <div class="table-responsive" style="max-height: 60vh;">
          <table class="table align-middle">
            <tbody>
              <tr *ngFor="let item of giftList; let i = index" class="d-flex">
                <td class="col-8 d-flex td-mobile">
                  <div
                    class="me-1"
                    style="max-width: 6rem;"
                  >
                    <div class="product-image">
                      <img
                        [src]="
                          item.imageUrls.length > 0
                            ? item.imageUrls[0]
                            : 'assets/images/product.png'
                        "
                        class="img-fluid"
                        alt="product"
                      />
                    </div>
                  </div>
                  <div class="d-none d-lg-grid d-xl-grid align-items-center text-start ps-3 py-4">
                    <div class="fw-bolder fs-6">{{ item.name }}</div>
                    <div class="theme-color">Quy đổi: {{item.giftExchangeInfo.cost}} lá xanh</div>
                  </div>
                  <div class="product border-0 d-contents d-lg-none d-xl-none">
                    <div class="product-detail w-100">
                      <ul>
                        <li class="name text-wrap">
                          <span class="fw-bolder fs-6">{{ item.name }}</span>
                          <div class="d-flex flex-wrap align-items-center gap-2 py-2">
                            <div class="theme-color">Quy đổi: {{item.giftExchangeInfo.cost}} lá xanh</div>
                            <div class="btn-gift">
                              <h6>Quà tặng</h6>
                            </div>
                          </div>
                        </li>
                        <li
                          class="d-lg-none d-xl-none d-flex gap-2 justify-content-between"
                        >
                          <div class="quantity-price div-col">
                            <div class="cart_qty">
                              <div class="input-group">
                                <app-counter-gift
                                  [cartItem]="item.cartItem"
                                  (userGiftPoint)="updateUserGiftPoint($event)"
                                  style="width: 100%"
                                ></app-counter-gift>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
                <td class="col-4 d-none d-lg-flex d-xl-flex align-items-center">
                  <app-counter-gift
                    [cartItem]="item.cartItem"
                    (userGiftPoint)="updateUserGiftPoint($event)"
                    style="width: 100%"
                  ></app-counter-gift>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>
