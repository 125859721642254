<footer class="section-t-space " [ngClass]="footer.footer_class">
  <div class="d-flex justify-content-center">
    <div class="container-fluid-lg container-fluid-md container-fluid-sm" style="max-width: 1400px ; width: 1400px;">
      <div class="main-footer section-b-space">
        <div class="row g-md-4 g-3">
          <div class="col-xl-5 col-lg-12">
            <div class="footer-logo">
              <app-footer-about [data]="data"></app-footer-about>
            </div>
          </div>
          <div
            class="col-xl col-lg-4 col-sm-4"
            *ngIf="data?.footer?.about_us?.link?.length"
            (click)="toggle('about_us')"
          >
            <div class="footer-contain">
              <app-footer-links
                [links]="data?.footer?.about_us?.link || []"
                [name]="data?.footer?.about_us?.name || ''"
                [logos]="data?.footer?.about_us?.logoapp?.logo || []"
                [logoname]="data?.footer?.about_us?.logoapp?.name || ''"
              ></app-footer-links>
            </div>
          </div>
          <div
            class="col-xl col-lg-4 col-sm-4"
            *ngIf="data?.footer?.help_center?.link?.length"
            (click)="toggle('help_center')"
          >
            <div class="footer-contain">
              <app-footer-links
                [links]="data?.footer?.help_center?.link || []"
                [name]="data?.footer?.help_center?.name || ''"
                [contactes]="
                  data?.footer?.help_center?.contactusapp?.contactus || []
                "
              ></app-footer-links>
            </div>
          </div>
          <div
            class="col-xl col-lg-4 col-sm-4"
            *ngIf="data?.footer?.useful_link?.link?.length"
            (click)="toggle('useful_link')"
          >
            <div class="footer-contain">
              <app-footer-links
                [links]="data?.footer?.useful_link?.link || []"
                [name]="data?.footer?.useful_link?.name || ''"
                [contactname]="
                  data?.footer?.useful_link?.contactusapp?.name || ''
                "
                [contactes]="
                  data?.footer?.useful_link?.contactusapp?.contactus || []
                "
              ></app-footer-links>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
  
  <div class="container-fluid-lg container-fluid-md container-fluid-sm" style="background-color: #EAEAEA;">
    <div>
      <h6 class="text-content text-center pt-4">{{data?.footer?.copyright_content}}</h6>
      <h6 class="text-content text-center pb-4">Version {{currentVersion}}</h6>
    </div>
  </div>
</footer>
