<div class="footer-logo-contain">
  <p *ngIf="data?.footer?.footer_about"><strong>{{ data?.footer?.footer_about }}</strong></p>
  <br />
  <ul>
    <li *ngIf="data?.footer?.about_address">
      <strong>Trụ sở chính : </strong>
      <p>{{ data?.footer?.about_address }}</p>
    </li>
    <li *ngIf="data?.footer?.about_boss" class="d-block">
      <strong>Tên nguời đại diện : </strong>
      <p class="d-inline">{{ data?.footer?.about_boss }}</p>
    </li>
    <li *ngIf="data?.footer?.about_cert_CBR">
      <strong>Số giấy chứng nhận đăng ký kinh doanh : </strong>
      <p class="d-inline">{{ data?.footer?.about_cert_CBR }}</p>
    </li>
    <li *ngIf="data?.footer?.about_cert_HC">
      <strong>Số giấy chứng nhận đủ điều kiện kinh doanh thuốc : </strong>
      <p class="d-inline">{{ data?.footer?.about_cert_HC }}</p>
    </li>
    <li class="d-flex pt-2">
      <div class="gap-3 d-flex" style="width: 50%;">
        <img 
        [src]="data?.footer?.logo_footer_btc"
        alt="logo"
        style="width: 50%"
        (click)="openNewTab('http://online.gov.vn/Home/WebDetails/118323')"
      />
        <img *ngIf="isBrowser"
          [src]="data?.footer?.logo_footer_dmca"
          alt="logo"
          style="width: 50%; height: 85%;"
        />
      </div>
    </li>
  </ul>
</div>
