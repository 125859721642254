<div class="footer-logo-contain">
  <p *ngIf="name"><strong>{{ name }}</strong></p>
  <br />
  <ul>
    <li *ngFor="let link of links">
      <a routerLink="/{{ link.link }}" class="text-content">{{
        link.label | titleCase
      }}</a>
    </li>
  </ul>
  <br />
  <ul >
    <p *ngIf="name"><strong>{{ contactname }}</strong></p>
    <li *ngFor="let contact of contactes">
      <div class="footer-number pt-2 d-flex">
        <img class="pe-2" src="assets/images/home/icon-call.svg" (click)="telPhone(contact.number_contact)" />
        <!-- <i class="ri-phone-line pe-2"></i> -->
        <div class="contact-number text-theme">
          <h6 class="text-content">
            {{contact.name}}
          </h6>
          <h5  (click)="telPhone(contact.number_contact)"><strong>{{ contact.number_contact }}</strong></h5>
        </div>
      </div>
    </li>
    
  </ul>
  <ul >
    <p *ngIf="name"><strong>{{ logoname }}</strong></p>
    <li class="g-3 pt-3">
      <div *ngFor="let logo of logos" class="pe-2 d-inline-block">
        <a href="{{logo.url}}"><img src="{{logo.src}}" alt="playstore" /></a>
      </div>
    </li>
    
  </ul>
</div>
