<!-- My Account Basic Style -->
<!--<div *ngIf="style == 'basic'">-->
<!--  <div class="delivery-login-box">-->
<!--    <div class="delivery-icon">-->
<!--      <i class="ri-user-line" *ngIf="!isAuthenticated; else profile"></i>-->
<!--      <ng-template #profile>-->
<!--        <div class="user-box">-->

<!--          <ng-template #placeholder>-->
<!--            <h3>-->
<!--              {{ user }}-->
<!--            </h3>-->
<!--          </ng-template>-->
<!--        </div>-->
<!--      </ng-template>-->
<!--    </div>-->
<!--    <div class="delivery-detail">-->
<!--      <h6>-->
<!--        {{ "hi" | translate }},-->
<!--        {{ isAuthenticated ? user : ("user" | translate) }}-->
<!--      </h6>-->
<!--      <h5>Thông tin người dùng</h5>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="onhover-div onhover-div-login">-->
<!--    <ul class="user-box-name">-->
<!--      <li class="product-box-contain" *ngIf="isAuthenticated">-->
<!--        <a [routerLink]="['/account']"-->
<!--          ><i class="ri-user-line me-2"></i> Thông tin người dùng</a-->
<!--        >-->
<!--      </li>-->
<!--      <li class="product-box-contain" *ngIf="isAuthenticated">-->
<!--        <a href="javascript:void(0)" (click)="confirmationModal.openModal()"-->
<!--          ><i class="ri-logout-box-r-line me-2"></i>-->
<!--          Đăng xuất</a-->
<!--        >-->
<!--      </li>-->
<!--    </ul>-->
<!--  </div>-->
<!--</div>-->
<!-- My Account Classic Style -->
<div class="onhover-dropdown ">
  <!--  white border for this div-->
  <div class="d-inline-block">
    <div class="d-inline-block" style="margin-right: -5px">
      <!--      <a href="javascript:void(0)" class="user-box" *ngIf="style == 'classic'">-->
      <!--        <span class="header-icon">-->
      <!--          <i class="ri-user-line"></i>-->
      <!--        </span>-->
      <!--        <div class="user-name">-->
      <!--          <h6 class="text-content">Thông tin người dùng</h6>-->
      <!--          <h4 class="mt-1">-->
      <!--            {{ isAuthenticated ? user : "User" }}-->
      <!--          </h4>-->
      <!--        </div>-->
      <!--      </a>-->
      <a href="javascript:void(0)" class="header-icon search-icon border-0" [routerLink]="['/account/my-wishlist']"
        *ngIf="style == 'standard'"> <small class="badge-number text-bg-warning">
          {{ totalQuantity <= 99 ? totalQuantity : '99+' }} </small>
            <i class="ri-heart-line text-white fs-3"> </i>
      </a>
    </div>
  </div>

  <!--  <div class="onhover-div onhover-div-login">-->
  <!--    <ul class="user-box-name">-->
  <!--      <li class="product-box-contain" *ngIf="isAuthenticated">-->
  <!--        <a [routerLink]="['/account']"-->
  <!--          ><i class="ri-user-line me-2"></i> Thông tin người dùng</a-->
  <!--        >-->
  <!--      </li>-->
  <!--      <li class="product-box-contain" *ngIf="isAuthenticated">-->
  <!--        <a href="javascript:void(0)" (click)="confirmationModal.openModal()"-->
  <!--          ><i class="ri-logout-box-r-line me-2"></i>-->
  <!--          Đăng xuất</a-->
  <!--        >-->
  <!--      </li>-->
  <!--    </ul>-->
  <!--  </div>-->
</div>

<app-confirmation-modal #confirmationModal [title]="'Xác nhận'"
  [message]="'Bạn chắc chắn muốn đăng xuất khỏi hệ thống ?'" (confirmed)="logout($event)">
</app-confirmation-modal>