import { Component, Input } from '@angular/core';
import { WService } from '@/app/shared/interface/common.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wservice-component',
  templateUrl: './wservice-component.component.html',
  styleUrl: './wservice-component.component.scss'
})
export class WserviceComponentComponent {
  @Input() dataupper: WService[];
  @Input() databot: WService[];

  constructor(
    private router: Router
  ) { }

  public dataform = {
    status: true,
    titleupper: "TẠO SAO BẠN NÊN LỰA CHỌN?",
    titlebottom: "GIÁ THUỐC TỐT",
    description: "Với giá trị cốt lõi \"GIÚP KHÁCH HÀNG MUA HÀNG\" Giá Thuốc Tốt luôn coi khách hàng là người thân để quan tâm, giúp đỡ và trao nhiều giá trị nhất tới khách hàng, tư vấn giúp khách hàng đưa ra những lựa chọn tốt nhất khi sử dụng dịch vụ.",
  }

  gotoIntrocude() {
    this.router.navigate(['/gioi-thieu-gia-thuoc-tot']);
  }

}
