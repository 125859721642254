<swiper
  [direction]="'vertical'"
  [loop]="true"
  [autoHeight]="true"
  [allowTouchMove]="false"
  [pagination]="false"
  [navigation]="false"
  [updateOnWindowResize]="true"
  [autoplay]="{ delay: 1000, disableOnInteraction: false }"
  [style]="'height:56px'"
  class=""
>
  <ng-container *ngFor="let item of content; let i = index">
    <ng-container *ngIf="item.content">
      <ng-template attr.data-swiper-autoplay="{{ 2000 * (i + 1) }}" swiperSlide>
        <div class="notification-slide" style="height: 100%;">
          <div class="timer-notification" style="height: 100%;">
            <img [src]="item.content" alt="notification" class="img-fluid" style="width: 100%;height: 100%;"/>

            <!-- <h6 [innerHtml]="item.content"></h6> -->
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</swiper>
