<section *ngIf="userTier && (userTier == 4 || userTier == 5)" class="pt-0">
  <div class="container-fluid-lg">
    <div class="mt-3 mb-4 p-3 warning d-flex align-items-center gap-2">
      <div>
        <img src="assets/svg/warning 1.svg" style="width: 40px; height: 40px" />
      </div>
      <div>
        Chú ý: Tài khoản của quý khách chưa hoàn thiện đầy đủ thông tin, vui
        lòng
        <span class="theme-color text-decoration-underline fw-bold"
          ><a [routerLink]="'/account/my-business-profile'"
            >bấm vào đây</a
          ></span
        >
        để cập nhật hồ sơ. Sau khi thông tin được cập nhật đầy đủ và được xác
        thực, quý khách có thể tiến hành hoàn tất đặt hàng.
      </div>
    </div>
  </div>
</section>
