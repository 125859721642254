import {
  Component,
  HostListener,
  Inject,
  Input,
  TransferState,
  afterNextRender,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription, firstValueFrom } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
// import $ from 'jquery';

import { ProductService } from '@/app/shared/services/product.service';
import { CommonService } from '@/app/shared/services/common.service';
import { CartService } from '@/app/shared/services/cart.service';

import { Cart } from '@/app/shared/interface/cart.interface';
import { SearchUserQueryString } from '@/app/shared/filter';
import { QueryStringService } from '@/app/shared/services/querystring.service';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '../../services/account.service';
import { DOCUMENT } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { AuthService, TOKEN, USERNAME } from '../../services/auth.service';

declare var $: any;

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent {
  @Input() style: string = 'basic';
  public isAccountActivated: boolean;
  private changeQueryStringSubcription?: Subscription;
  private getCartSubscription?: Subscription;
  public searchQuery: SearchUserQueryString = new SearchUserQueryString();

  public term = new FormControl();
  public show: boolean = false;

  public items: any[] = [];
  public itemsExtend: any[] = [];
  public inputText: string;
  public showSearchResult: boolean = false;
  public loaderStatus: boolean = false;

  private searchSubject = new Subject<string>();
  private readonly debounceTimeMs = 300; // Set the debounce time (in milliseconds)

  public cart: Cart;

  public isAuthenticated: boolean = false;

  public skeletonLoader: boolean = false;
  public skeletonItems = Array.from({ length: 5 }, (_, index) => index);

  public body = {
    limit: 5,
    searchTerm: '',
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private commonService: CommonService,
    private cartService: CartService,
    private queryStringService: QueryStringService,
    private toastService: ToastrService,
    private accountService: AccountService,
    private cookieService: SsrCookieService,
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private transferState: TransferState
  ) {
    afterNextRender(() => {
      // this.inputText = this.searchQuery.value;
      $('#searchInput').on('focus', () => {
        if (this.items.length > 0 || this.itemsExtend.length > 0) {
          this.showSearchResult = true;
        }
      });
      $('#searchInput').on('blur', () => {
        setTimeout(() => {
          this.showSearchResult = false;
        }, 150);
      });
      $('#searchInput').on('change', () => {
        if ($('#searchInput').val() == '') {
          this.items = [];
        }
      });
    });
    this.changeQueryStringSubcription =
      this.queryStringService.eventChangeQueryString.subscribe(() => {
        this.searchQuery = this.queryStringService.getQueryString(
          new SearchUserQueryString()
        );
      });
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    if (
      targetElement &&
      !targetElement.closest('.searchbar-container') &&
      !targetElement.closest('.dropdown-list') &&
      targetElement.id !== 'searchInput'
    ) {
      this.showSearchResult = false;
    }
  }

  @HostListener('keyup', ['$event'])
  onEnterKeyPressed(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.skeletonLoader = false;
      this.showSearchResult = false;
      this.redirectToSearch();
    }
  }

  ngOnInit() {
    // const localStorage = this.document.defaultView?.localStorage;
    // const token = this.cookieService.get('token');

    const token = this.transferState.get(TOKEN, '');
    const userName = this.transferState.get(USERNAME, '');
    // const userName = this.cookieService.get('username');
    let jwtToken = this.commonService.parseJwt(token!);
    this.isAuthenticated = this.authService.isAuthenticated();
    if (this.isAuthenticated) {
      this.isAccountActivated = this.authService.isAccountActivated();
    }
    this.searchSubject
      .pipe(debounceTime(this.debounceTimeMs))
      .subscribe((searchValue) => {
        this.search(searchValue);
      });
    this.getCartSubscription = this.commonService.getCart.subscribe({
      next: (res: any) => {
        if (res) {
          this.cart = res;
          // this.cartTotal = this.cart.items.length;
        }
      },
    });

    this.commonService.authEvent.subscribe({
      next: (data: boolean) => {
        this.isAuthenticated = data;
      },
    });
  }

  redirectToSearch() {
    let redirectToProductsPage = '/product';
    let currentPath = this.router.url;
    let pathParts = currentPath.split('/');
    this.searchQuery.value = this.inputText;
    if (currentPath.includes(redirectToProductsPage) && pathParts.length < 3) {
      this.queryStringService.changeQueryString(this.searchQuery);
    } else {
      this.router
        .navigate([redirectToProductsPage], {
          relativeTo: this.route,
        })
        .then(() => {
          this.queryStringService.changeQueryString(this.searchQuery);
        });
    }
  }

  toggleSearchBox() {
    // this.show = !this.show;
    this.showSearchResult = false;
  }

  input($event: any) {
    const ignoredKeys = [
      'Enter',
      'Shift',
      'Control',
      'Alt',
      'Meta',
      'ArrowUp',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'CapsLock',
      'Tab',
      'Escape',
      'F1',
      'F2',
      'F3',
      'F4',
      'F5',
      'F6',
      'F7',
      'F8',
      'F9',
      'F10',
      'F11',
      'F12',
    ];
    if (ignoredKeys.includes($event.key)) {
      return;
    }

    if (this.inputText) {
      // this.skeletonLoader = true;
      // this.items = [];
      this.showSearchResult = true;
      this.searchSubject.next(this.inputText);
    } else {
      this.items = [];
    }
  }

  search(value: string) {
    this.body.searchTerm = value;
    this.loaderStatus = true;
    this.itemsExtend = [];

    const token = this.transferState.get(TOKEN, '');

    if (this.showSearchResult) {
      //this.skeletonLoader = true;

      if (token) {
        this.productService
          .searchProductsForMember(JSON.stringify(this.body))
          .subscribe({
            next: async (res: any) => {
              this.items = res.products;
              if (res.products.length < this.body.limit) {
                await this.searchExtend(this.body.limit, value);
              }
              this.itemsExtend = this.itemsExtend.filter(
                (item) => !this.items.some((i) => i.id === item.id)
              );
              this.showSearchResult = true;
            },
            error: (error: any) => {
              console.log(error);
            },
            complete: () => {
              this.skeletonLoader = false;
              this.loaderStatus = false;
            },
          });
      } else {
        this.productService.getProductsForGuest(JSON.stringify(this.body)).subscribe({
          next: async (res: any) => {
            this.items = res.products;

            if (res.products.length < this.body.limit) {
              await this.searchExtend(this.body.limit, value);
            }
            this.itemsExtend = this.itemsExtend.filter(
              (item) => !this.items.some((i) => i.id === item.id)
            );
            
            this.showSearchResult = true;
          },
          error: (error: any) => {
            console.log(error);
          },
          complete: () => {
            this.skeletonLoader = false;
            this.loaderStatus = false;
          },
        });
      }
    }
  }

  async searchExtend(limit: number, value: string) {
    let body = {
      limit: limit,
      searchTerm: value,
    };
    this.skeletonLoader = true;

    const token = this.transferState.get(TOKEN, '');

    if (token) {
      const res = await firstValueFrom(
        this.productService.getProductsForMember(JSON.stringify(body))
      );

      if (res.products.length > 0) {
        this.itemsExtend = res.products;
      }
    } else {
      const res = await firstValueFrom(
        this.productService.getProductsForGuest(JSON.stringify(body))
      );

      if (res.products.length > 0) {
        this.itemsExtend = res.products;
      }
    }
  }

  onSearchInputFocus() {
    if (this.items.length > 0) {
      this.showSearchResult = true;
    }
  }

  addToCart(item: any) {
    let body = {
      productId: item.productID,
      quantity: 1,
    };
    for (let cartItem of this.cart.items) {
      if (cartItem.productID == item.productID) {
        body.quantity = cartItem.quantity + 1;
        break;
      }
    }
    this.cartService.updateCart(JSON.stringify(body)).subscribe({
      next: (res: any) => {
        this.commonService.setCart(res);
        this.toastService.success('Đã cập nhật giỏ hàng!', '', {
          positionClass: 'toast-top-right',
        });
      },
      error: (error: any) => {
        console.log(error);
        this.toastService.warning(error.error.message, '', {
          positionClass: 'toast-top-right',
        });
      },
    });
  }
  ngOnDestroy() {
    this.getCartSubscription?.unsubscribe();
    this.changeQueryStringSubcription?.unsubscribe();
  }
}
