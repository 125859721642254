import { Component, Input } from '@angular/core';
import { Option } from '@/app/shared/interface/common.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {

  @Input() textClass: string = 'text-white f-w-600';
  @Input() data: Option | null;
  @Input() logo: string | null | undefined;

  themeOption: Option;

}
