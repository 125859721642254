import { Component } from '@angular/core';
import * as data from '@/app//shared/data/owl-carousel';
import { Input } from '@angular/core';

@Component({
  selector: 'app-feedback-home',
  templateUrl: './feedback-home.component.html',
  styleUrl: './feedback-home.component.scss',
})
export class FeedbackHomeComponent {
  @Input() style: string = 'horizontal';
  @Input() class: string | null;
  @Input() contentClass: string;
  @Input() banners: any;

  public data = {
    header: 'KHÁCH HÀNG',
    header2: ' NÓI GÌ VỀ GIÁ THUỐC TỐT',
  };

  public bannerSlider = data.customOptionsItem2;
}
