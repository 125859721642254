<section class="pb-5" *ngIf="order">
  <div class="container-fluid-lg d-flex justify-content-center">
    <div
      class="col-xxl-8 col-lg-8 col-md-12 col-sm-12 p-4 border bg-white d-grid justify-content-center"
      style="border-radius: 21px"
    >
      <div class="text-center">
        <!-- <img
          class="pb-3"
          style="max-width: 60%; max-height: 60%"
          src="assets/svg/check 12.svg"
          alt="success"
        />
        <h2>Cảm ơn quý khách đã mua hàng tại Giathuoctot.com</h2> -->
        <h4 style="color: #FF0000;" class="fw-bold" *ngIf="!isPaid">QUÉT QR CODE ĐỂ THANH TOÁN ĐƠN HÀNG</h4>
      </div>
      
      <div class="text-center pb-2">
        <h3>Mã đơn hàng: <a [routerLink]="['/account/my-order', order.orderId ]"><span class="theme-color">{{ order.orderId }}</span></a></h3>
        <h3 *ngIf="order?.paymentMethod && isAuthenticated">
          Hình thức thanh toán:
          <span>{{ getPaymentMethod(order.paymentMethod!) }}</span>
        </h3>
        <h3>
          Tổng tiền thanh toán: <span class="theme-color">{{ order.finalPrice | currencySymbol }}</span>
        </h3>
      </div>
      <div class="text-center pb-2 m-auto" style="width: 60%;" *ngIf="!isPaid">
        <div style="background-color: #F3F3F3; padding: 1rem 3rem; border-radius: 20px;">
          <div class="pb-2 p-3">
            <app-qr-code [qrImgSrc]="qrImgSrc"></app-qr-code>
          </div>
          <div class="d-grid gap-2">
            <h4 class="fw-bold">{{qrData.accName}}</h4>
            <h5>{{qrData.accNo}}</h5>
            <h5>Nội dung: <span class="fw-bold">{{qrData.des}}</span></h5>
          </div>
        </div>
        <div class="pt-1">
          <button 
            class="d-flex align-items-center justify-content-center gap-2 px-3 py-2 border-0 m-auto"
            style="border-radius: 10px; background-color: #F3F3F3; width: 95%; white-space: wrap;"
            (click)="downloadQRCode()" 
          >
            <img src="assets/svg/download_qr_green.svg" alt="download QR" style="width: 24px; height: 24px;" />
            <h5 class="theme-color">Nhấn để tải xuống mã QR</h5>
          </button>
        </div>
      </div>
      <div class="fw-bold text-center pb-2 m-auto ng-star-inserted" style="width: 60%;" *ngIf="!isPaid">
        <!-- <h4 style="color: #FF0000;" class="fw-bold">QUÉT QR CODE ĐỂ THANH TOÁN ĐƠN HÀNG</h4> -->
        <h4 class="fw-bold">(Hệ thống sẽ xác nhận đơn hàng tự động khi thanh toán hoàn tất)</h4>
        <h4 class="text-center pb-2 m-auto ng-star-inserted" style="width: 60%;">
          Quý vị vui lòng 
          <span class="theme-color fw-bold">KHÔNG CHỈNH SỬA SỐ TIỀN VÀ NỘI DUNG</span>
          trong khi thanh toán
        </h4>
      </div>
      <div
        *ngIf="!isPaid"
        class="text-center pb-2 m-auto ng-star-inserted"
        style="border-bottom: var(--bs-border-width) solid var(--theme-color); width: 60%;"
      >
        <div class="p-3 border">
          <h4 class="fw-bold">Thông tin nhận hàng</h4>
        </div>
        <div class="p-3 border border-top-0">
          <ul class="d-grid gap-3">
            <li class="d-flex gap-3">
              <img src="assets/svg/user 2.svg" />
              <h5>{{ order.receiverInfo?.userName }}</h5>
            </li>
            <li class="d-flex gap-3">
              <img src="assets/svg/telephone 1.svg" />
              <h5>{{ order.receiverInfo?.phone }}</h5>
            </li>
            <li class="d-flex gap-3">
              <img src="assets/svg/pin 1.svg" />
              <h5>{{ order.receiverInfo?.address }}</h5>
            </li>
          </ul>
        </div>
      </div>
      <div class="d-flex justify-content-center pt-5" *ngIf="!isPaid">
        <button
          class="btn col-6 theme-color"
          style="border: var(--bs-border-width) solid var(--theme-color)"
          (click)="gotoQuickOrder()"
        >
          Mua thêm sản phẩm khác
        </button>
      </div>
      <div class="text-center" *ngIf="isPaid">
        <div class="py-5">
          <img src="assets/svg/success.svg" alt="success" style="max-width: 80%; max-height: 80%" />
        </div>
        <div class="d-grid pt-3 gap-2 text-center">
          <h2 *ngIf="isAuthenticated" class="theme-color">THANH TOÁN THÀNH CÔNG!</h2>
          <h2 *ngIf="!isAuthenticated" class="theme-color">ĐẶT HÀNG THÀNH CÔNG!</h2>
          <h6 style="color: #545454;">Cảm ơn quý khách đã mua hàng</h6>
          <button
            class="btn mt-3 w-75 m-auto"
            style="color: #fff; background-color: #0C9447; border-radius: 999px;"
            (click)="gotoHome()"
          >QUAY LẠI TRANG CHỦ</button>
        </div>
      </div>
    </div>
  </div>
</section>
