import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { Voucher } from "../interface/voucher.interface";

@Injectable({
  providedIn: "root",
})
export class VoucherService {

  constructor(private http: HttpClient) {}

  getVouchers() {
    return this.http.get<Voucher[]>(`${environment.baseURL}vouchers`);
  }

  getAvailableVouchers() {
    return this.http.get<Voucher[]>(`${environment.baseURL}vouchers/available`);
  }

  getVoucherHistories() {
    return this.http.get<Voucher[]>(`assets/data/voucher-history.json`);
  }

}
