import {
  Component,
  TemplateRef,
  ViewChild,
  PLATFORM_ID,
  Inject,
  Output,
  EventEmitter
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrl: './forgot-password-modal.component.scss',
})
export class ForgotPasswordModalComponent {

  @Output() openRegistryModal: EventEmitter<any> = new EventEmitter();
  @ViewChild('forgotPasswordModal', { static: false }) ForgotPasswordModal: TemplateRef<string>;

  public closeResult: string;
  public modalOpen: boolean = false;

  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }


  async openModal() {
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalOpen = true;
      this.modalService
        .open(this.ForgotPasswordModal, {
          ariaLabelledBy: 'address-add-Modal',
          centered: true,
          windowClass: 'theme-modal modal-login',
        })
        .result.then(
          (result) => {
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  private getDismissReason(reason: ModalDismissReasons): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  handleButtonClick(event: Event) {
    this.modalService.dismissAll();
    this.openRegistryModal.emit(true);
  }

}
