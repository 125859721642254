<ng-container *ngIf="image?.redirect_link?.link_type === 'external_url'; else collection">
  <a href="{{ image?.redirect_link?.link }}" target="_blank" class="banner-contain d-block h-100">
    <div class="h-100 bg-size" [ngClass]="class" *ngIf="bgImage" [ngStyle]="{'background-image': 'url(' + image?.image_url + ')'}"></div>
    <img *ngIf="!bgImage" src="{{ image?.image_url }}" class="h-100 bg-img w-100" alt="{{ image?.image_url }}">
  </a>
</ng-container>
<ng-template #collection>
  <ng-container *ngIf="image?.redirect_link?.link_type === 'collection'; else product">
    <a [routerLink]="['/collections']" [queryParams]="{ category: image?.redirect_link?.link }" >
      <div class="h-100 bg-size" [ngClass]="class" *ngIf="bgImage" [ngStyle]="{'background-image': 'url(' + image?.image_url + ')'}"></div>
      <img *ngIf="!bgImage" src="{{ image?.image_url }}" class="h-100 bg-img w-100" alt="{{ image?.image_url }}">
    </a>
  </ng-container>
</ng-template>
<ng-template #product>
  <ng-container *ngIf="image?.redirect_link?.link_type === 'product' else onlyImage">
    <a [routerLink]="['/product/', getProductSlug(image?.redirect_link?.link, products)]">
      <div class="h-100 bg-size" [ngClass]="class" *ngIf="bgImage" [ngStyle]="{'background-image': 'url(' + image?.image_url + ')'}"></div>
      <img *ngIf="!bgImage" src="{{ image?.image_url }}" class="h-100 bg-img w-100" alt="{{ image?.image_url }}">
    </a>
  </ng-container>
</ng-template>
<ng-template #onlyImage>
    <div class="h-100 bg-size" [ngClass]="class" *ngIf="bgImage" [ngStyle]="{'background-image': 'url(' + image?.image_url + ')'}"></div>
    <img *ngIf="!bgImage" src="{{ image?.image_url }}" class="h-100 bg-img w-100" alt="{{ image?.image_url }}">
</ng-template>
