    import { Component,OnInit } from '@angular/core';
    import { ProductService } from '@/app/shared/services/product.service';
    import { CommonModule } from '@angular/common';
    import { Breadcrumb } from '@/app/shared/interface/breadcrumb';
    import { PaginateModel } from '@/app/shared/interface/core.interface';
    import { PaginationModel, IngredientPaginationModel, RetriveUserModel, Ingredient } from '@/app/shared/interface/product.interface';
    import { QueryStringService } from '@/app/shared/services/querystring.service';
    import { ActivatedRoute } from '@angular/router';
    import { Subscription } from 'rxjs';


    @Component({
      selector: 'app-ingredient',
      templateUrl: './ingredient.component.html',
      styleUrl: './ingredient.component.scss',
    })
    export class IngredientComponent implements OnInit{

      public body: any;
      private changeDataSubcription?: Subscription;
      public ingredients: Ingredient[] = [];
      public paginatedList: any[] = [];
      _currentPage: number = 1;
      _paginateRange: number = 7;
      bodyIngredient = {
        limit: 52,
        offset: 0,
      };

      public offCanvasMenu: boolean = false;

      public selectedCategory: string;
      public searchText: string = '';
      public selectedLetter: string = '';
      public totalItems: number = 0;
      public filteredList: any;
      public skeletonLoader: boolean = true;
      public breadcrumb: Breadcrumb = {
        title: "Hoạt Chất",
        items: [{ label: 'Hoạt Chất', active: false }]
      };
      alphabetArray: string[] = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];

      public ingredientCategory: string[] = ["Thuốc","Không phải thuốc"]
      public searchTextCategory: string;

      constructor(public productService: ProductService,
        private queryStringService: QueryStringService,) { }

      ngOnInit(): any {
          this.getIngredient();
      }

      getIngredient() {
        let body = this.queryStringService.buildFetchingModel(this.bodyIngredient.limit);
        this.skeletonLoader = true;
        this.productService.getIngredientPagination(body).subscribe({
          next: (res: IngredientPaginationModel) => {
            this.skeletonLoader = false;
            if (res.ingredient) {
              this.ingredients = res.ingredient;
              let pagination: PaginationModel = {
                limit: body.limit,
                offset: body.offset,
                searchTerm: "",
                totalItems: this.ingredients.length,
                sortBy: "",
                category: "",
              };
              this.queryStringService.changePagination(pagination);

          }}})
          error: (error: any) => {
            console.log(error);
          }
          complete: () => {
            this.skeletonLoader = false;
          }
        }
    }


