<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
<div class="container-fluid-lg">
    <article *ngIf="postDetail" class="bg-white rounded rounded-3 py-4 px-2 mb-3 ck-content">
        <div class="row">
            <div class="col post-detail">
                <header class="entry-header">
                    <h1 class="entry-title text-center mb-2">{{postDetail.postTitle}}</h1>
                    <!-- <div class="d-flex justify-content-center gap-3 text-muted mb-4">
                        <div *ngIf="postDetail.author">
                            <i class="ri-account-circle-fill"></i> {{postDetail.author.userName}}
                        </div>
                        <div *ngIf="postDetail.postCreatedAt">
                            <i class="ri-time-line"></i> {{postDetail.postCreatedAt | date : "dd/MM/yyyy"}}
                        </div>
                        <div *ngIf="postDetail.viewed">
                            <i class="ri-eye-line"></i> {{postDetail.viewed}}
                        </div>
                    </div> -->
                </header>
                <div class="entry-content px-4">
                    <div *ngIf="postDetail.postThumbnailUrl" class="text-center">
                        <img [src]="postDetail.postThumbnailUrl" [alt]="postDetail.postTitle" class="rounded w-100">
                    </div>
                    <div [innerHTML]="postDetail.postDescription"></div>
                    <app-blog-post-menu [blogPostTag]="pageType"></app-blog-post-menu>
                    <div class="post-content" [innerHTML]="postDetail.postContent"></div>
                </div>
            </div>
        </div>
        <app-blog-rating [rating]="postDetail.rate"></app-blog-rating>
    </article>

    <!--<div *ngIf="postDetail.advImageUrl" class="post-adv mb-3 rounded rounded-3" style="background-image: url({{postDetail.advImageUrl}})">
         <img [src]="postDetail.advImageUrl" [alt]="postDetail.postTitle" class="img-fluid rounded rounded-3">
    </div> -->

    <div class="bg-white rounded rounded-3 py-4 px-2 mb-3 post-relative">
        <div class="p-3">
            <h3 class="d-inline-block">
                Có thể bạn quan tâm
            </h3>
        </div>
        <div class="row">
            <ng-container *ngFor="let r of postDetail.relativedPosts">
                <div class="col-md-4 col-sm-12">
                    <div class="card">
                        <a routerLink='/{{r.slug}}' >
                            <img [src]="r.postThumbnailUrl"  class="card-img-top rounded rounded-3 mb-3" [alt]="r.postTitle" >
                          </a>
                        <div class="card-body">
                            <h5 class="card-title fw-bold entry-title ">
                                <a routerLink='/{{r.slug}}' >{{r.postTitle}}</a></h5>
                            <p class="card-text" ><small class="text-muted"><i class="ri-time-line "></i> {{r.postCreatedAt | date : "dd/MM/yyyy" }}</small></p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>