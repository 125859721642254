import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FlashSaleService {

  private flashSale: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public getFlashSaleEvents = this.flashSale.asObservable();
  public setFlashSaleEvents(data: any) {
    this.flashSale.next(data);
  }

  constructor(private http: HttpClient) {}

  getFlashSale() {
    return this.http.get<any>(`${environment.baseURL}get-flashsale-event`);
  }

  getFlashSaleInfo() {
    return this.http.get(`${environment.baseURL}flashsale-info`);
  }

}