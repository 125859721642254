<!-- Banner Horizontal Style-->
<section>
  <div
    class="container-fluid-lg container-fluid-md container-fluid-xs px-2"
    *ngIf="banners?.length && style == 'horizontal'"
  >
    <div class="banner-slider">
      <h2 class="d-none d-lg-block d-xl-block pb-3">
        <strong class="fw-bold">THƯƠNG HIỆU NỔI BẬT</strong>
      </h2>
      <h2 class="d-lg-none d-xl-none text-theme pb-3">
        <strong class="fw-bold">THƯƠNG HIỆU NỔI BẬT</strong>
      </h2>
      <owl-carousel-o [options]="bannerSlider">
        <ng-container carouselSlide *ngFor="let banner of banners">
          <ng-container *ngIf="banner?.status">
            <ng-template carouselSlide>
              <div *ngIf="banner.redirect_link.link">
              <a [routerLink]="banner.redirect_link.link">
                <div class="banner-contain hover-effect">
                  <app-image-link
                    [class]="'banner-contain'"
                    [image]="banner"
                    [bgImage]="false"
                  ></app-image-link>
                </div>
              </a>
              </div>
              <div *ngIf="!banner.redirect_link.link">
                  <div class="banner-contain hover-effect">
                    <app-image-link
                      [class]="'banner-contain'"
                      [image]="banner"
                      [bgImage]="false"
                    ></app-image-link>
                  </div>
                </div>
            </ng-template>
          </ng-container>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
  <!-- Banner Vertical Style-->
  <div [class]="class" *ngIf="banners?.image_url && style == 'vertical'">
    <div class="home-contain hover-effect">
      <app-image-link
        [class]="'banner-contain'"
        [image]="banners"
        [bgImage]="false"
      ></app-image-link>
    </div>
  </div>
  <!-- Banner Full Width Style-->
  <div *ngIf="banners?.image_url && style == 'full_width'">
    <app-image-link
      [class]="'banner-contain'"
      [image]="banners"
      [bgImage]="false"
    ></app-image-link>
  </div>
</section>
