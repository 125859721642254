import { Component, Output, EventEmitter } from '@angular/core';
import { Observable, filter } from 'rxjs';
import { Menu } from '@/app/shared/interface/menu.interface';
import {
  Product,
  ProductModel,
} from '@/app/shared/interface/product.interface';
import { Blog, BlogModel } from '@/app/shared/interface/blog.interface';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {

  @Output() active: EventEmitter<boolean> = new EventEmitter();
  // product$: Observable<Product[]>;
  // blog$: Observable<BlogModel>;

  public menu: Menu[] = [
    {
      id: 1,
      title: 'Trang Chủ',
      type: 'link',
      megaMenu: true,
      megaMenuType: 'link',
      path: '',
      active: false,
    },
    {
      id: 2,
      title: 'Sản Phẩm',
      type: 'link',
      megaMenu: true,
      megaMenuType: 'link',
      path: 'product',
      active: false,
      slider: 'product',
    },
    //{
      //id: 3,
      //title: 'Hoạt chất',
      //type: 'link',
      //megaMenu: true,
      //megaMenuType: 'link',
      //slider: 'banner_portrait',
      //path: 'ingredient',
      //active: false,
    //},
    // {
    //   id: 6,
    //   title: 'Deal Sốc Mỗi Ngày',
    //   type: 'link',
    //   megaMenu: true,
    //   megaMenuType: 'link',
    //   slider: 'banner_portrait',
    //   path: 'flash-sale',
    //   active: false,
    //   isHot: true, 
    // },
    
    {
      id: 3,
      title: 'Đặt Hàng Nhanh',
      type: 'link',
      megaMenu: true,
      megaMenuType: 'link',
      slider: 'banner_portrait',
      path: 'quick-order',
      active: false,
    },
  ];
  public products: Product[];
  public blogs: Blog[];

  constructor(
    private router: Router
  ) {
    // this.product$.subscribe((product) => {
    //   this.products = product.slice(0, 2);
    // });
    // this.blog$.subscribe((blog) => {
    //   this.blogs = blog.data.slice(0, 2);
    // });
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe((v: any) => {
      this.activeMenu(v.url);
    });
  }

  activeMenu(path: string) {
    for (const m of this.menu) {
      m.active = false;
    }
    let activeMenu = this.menu.find(e => e.path && path.includes(e.path));
    if (activeMenu) {
      activeMenu.active = true;
    }
  }



  toggle(menu: Menu) {
    if (!menu.active) {
      this.menu.forEach((item) => {
        if (this.menu.includes(menu)) {
          item.active = false;
        }
      });
    }
    menu.active = !menu.active;
  }

  closeMenuOnClick(val: boolean) {
    this.active.emit(false);
  }
}
