<section class="d-flex align-items-center vh-100">
  <div class="container-fluid-lg">
    <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12 d-lg-flex d-xl-flex d-md-block d-sm-block">
      <div class="col-6 mobile">
        <img src="assets/images/404.png" class="img-fluid" alt="error 404" />
      </div>
      <div class="col-6 text-center gap-3 d-grid p-5 mobile mobile-p-0">
        <div class="contain-404">
          <h2 class="fs-1" style="text-transform: none">
            Xin lỗi, Trang bạn tìm kiếm không tồn tại!
          </h2>
        </div>
        <div class="col-12 d-flex justify-content-evenly">
          <div class="col-4 mobile-w-45 mobile-p-0" style="color: #6a6a6a">
            <span>
              <p class="fs-4">Trở về trang chủ</p>
              <p class="fs-4">Giá thuốc tốt</p>
            </span>
            <app-button
              [class]="
                'btn btn-md text-white theme-bg-color mt-4 mx-auto w-100 p-3 fs-4 mobile-btn'
              "
              style="background: #247636 !important; border-radius: 999px"
              [id]="'back_button'"
              [spinner]="false"
              (click)="gotoHome()"
            >
              Trang chủ
            </app-button>
          </div>
          <div class="col-4 mobile-w-45 mobile-p-0" style="color: #6a6a6a">
            <span>
              <p class="fs-4">Gọi hỗ trợ</p>
              <p class="fs-4">miễn phí</p>
            </span>
            <app-button
              [class]="
                'btn btn-md text-white theme-bg-color mt-4 mx-auto w-100 p-3 fs-4 mobile-btn'
              "
              style="background: #247636 !important; border-radius: 999px"
              [id]="'back_button'"
              [spinner]="false"
              (click)="callPhone()"
            >
              {{ phoneNumber }}
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
