import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Product, ProductModel } from '@/app/shared/interface/product.interface';

@Component({
  selector: 'app-image-link',
  templateUrl: './image-link.component.html',
  styleUrls: ['./image-link.component.scss']
})
export class ImageLinkComponent {

  products: Product[] = [];

  @Input() image: any;
  @Input() link: string;
  @Input() bgImage: boolean;
  @Input() class: string;

  constructor(){}

  getProductSlug(id: string, products: Product[]){
    let product = products.find(product => product.id === id);
    return product ? product.slug : null;
  }

}
