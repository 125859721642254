<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
<section class="section-b-space shop-section">
<div class="container-fluid-lg">
  <div class="row">
    <div class="col-custome-3">
      <div class="left-box" style="border-radius: 10px;">
        <div style="padding-right: 25px;">
          <input type="text" placeholder="Nhập tên hoạt chất cần tìm kiếm..." class="form-control border-1 ng-untouched ng-pristine ng-valid ri-search-line">
        </div>
        <div style="color: green;"><i>Có <b>... hoạt chất</b> cho kết quả tìm kiếm</i></div>
        <br>
        <div style="padding-right: 25px;">
          <h4 style="background-color: green; color: white; padding: 10px; border-radius: 10px;">Phân Loại</h4>
          <br>
          <div style="padding-right: 10px; padding-left: 10px;">
            <div class="text-center" *ngFor="let category of ingredientCategory">
              <div class="row" style="padding: 10px; border: 0px solid green; border-radius: 10px;">{{ category }}</div>
            </div>
          </div>
          <div class="row" style="padding: 10px">
            <div class="row" style="padding-left: 15px;">
              <ng-container *ngFor="let letter of alphabetArray.flat()">
                <div class="col-md-3" style="padding: 10px;">
                  <div class="row" style="border: 0px solid green; border-radius: 10px; padding-left:10px;">{{ letter }}</div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-custome-9">
      <div>
        <div class="row text-center">
          <div class="col-6 col-sm-4 col-md-3">
            <div>
              <p style="padding: 5px;"></p>
            </div>
          </div>
          <div class="text-center col-md-12">Xin lỗi, không có hoạt chất nào trùng khớp với từ khóa của bạn</div>
        </div>
          <app-collection-paginate>
          </app-collection-paginate>
      </div>
    </div>
  </div>
</div>
</section>