<ng-template #voucherModal let-modal>
  <div class="modal-body d-block d-sm-none">
    <ng-container>
      <i class="ri-question-line icon-box wo-bg"></i>
      <h5 class="modal-title text-center">Chọn mã giảm giá</h5>
      <div class="voucher">
        <div *ngFor="let voucher of vouchers; let i = index" class="">
          <div class="justify-content-between ps-3 pt-2 gap-3">
            <div class="d-flex flex-column justify-content-evenly pb-2">
              <h3 class="color-red">
                Giảm
                <span *ngIf="voucher.discountType == 1; else percent">{{
                  voucher.discountValue | currencySymbol
                  }}</span>
                <ng-template #percent>
                  {{ voucher.discountValue }} %
                </ng-template>
              </h3>
              <h6>
                Giảm giá
                <span *ngIf="voucher.discountType == 0"> tối đa&nbsp; </span>
                <span class="fw-bold" *ngIf="voucher.discountType == 0">
                  {{ voucher.maximumAmount | currencySymbol }}
                </span>
                cho đơn hàng từ
                <span class="fw-bold">
                  {{ voucher.minimumSpentRequired | currencySymbol }}
                </span>
              </h6>
              <h6>
                Số lượng mã giảm giá:
                <span class="text-danger fw-bold">{{
                  voucher.remainingUses
                  }}</span>
              </h6>
              <h6>
                Số lượt sử dụng/ngày:
                <span class="text-danger fw-bold">{{
                  voucher.usesLimitPerDay > 9999
                  ? "Không giới hạn"
                  : voucher.usesLimitPerDay
                  }}</span>
              </h6>
            </div>
            <div class="d-grid gap-1" style="
            color: #e88438;
            border: 1px dotted #e88438;
            border-radius: 10px;
          ">
              <span class="d-flex gap-1" style="background-color: #fff6ef" (click)="popupDetail(voucher)">
                <h4 class="btn-sm">
                  <img class="me-1" src="assets/svg/question 1.svg" />
                  <span> Nhấn xem điều kiện</span>
                </h4>
              </span>
            </div>
          </div>
          <div class="justify-content-between align-items-center p-3 border-dotted">
            <div class="row pb-2">
              <h6>HSD: {{ voucher.conditions?.end | date : "dd/MM/yyyy" }}</h6>
            </div>

            <div class="row d-flex gap-2">
              <button class="voucher-btn d-flex gap-1 align-items-center" (click)="applyVoucher(voucher)">
                Sử dụng ngay
              </button>
              <button class="voucher-btn d-flex gap-1 align-items-center" (click)="copyToClipboard(voucher)">
                {{ voucher.code }}
                <img src="assets/svg/copy 3.svg" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="p-3 bg-white rounded-3" *ngIf="!vouchers.length">
      <app-no-data [class]="'no-data-added position-relative'" [description]="'Bạn chưa có mã giảm giá nào'">
      </app-no-data>
    </div>
  </div>

  <div class="modal-body d-none d-sm-block">
    <div>
      <i class="ri-question-line icon-box wo-bg"></i>
      <h5 class="modal-title text-center">Chọn mã giảm giá</h5>
      <div class="voucher">
        <div *ngFor="let voucher of vouchers; let i = index" class="card-voucher d-grid">
          <div class="d-flex justify-content-between ps-3 pt-2 pe-3 gap-3">
            <div class="d-flex flex-column justify-content-evenly">
              <h3 class="color-red">
                Giảm
                <span *ngIf="voucher.discountType == 1; else percent">{{
                  voucher.discountValue | currencySymbol
                  }}</span>
                <ng-template #percent>
                  {{ voucher.discountValue }} %
                </ng-template>
              </h3>
              <h6>
                Giảm giá
                <span *ngIf="voucher.discountType == 0"> tối đa&nbsp; </span>
                <span class="fw-bold" *ngIf="voucher.discountType == 0">
                  {{ voucher.maximumAmount | currencySymbol }}
                </span>
                cho đơn hàng từ
                <span class="fw-bold">
                  {{ voucher.minimumSpentRequired | currencySymbol }}
                </span>
              </h6>
              <h6>
                Số lượng mã giảm giá:
                <span class="text-danger fw-bold">{{
                  voucher.remainingUses
                  }}</span>
              </h6>
              <h6>
                Số lượt sử dụng/ngày:
                <span class="text-danger fw-bold">{{
                  voucher.usesLimitPerDay > 9999
                  ? "Không giới hạn"
                  : voucher.usesLimitPerDay
                  }}</span>
              </h6>
            </div>
            <div class="d-grid gap-1">
              <div class="position-relative justify-content-center d-flex align-items-center">
                <span class="position-absolute text-white">
                  x{{ voucher.remainingUses }}
                </span>
                <img class="m-auto" src="assets/svg/itemvoucher.svg" />
              </div>
              <span class="d-flex gap-1" style="background-color: #fff6ef" (click)="popupDetail(voucher)">
                <h4 class="btn btn-sm voucherSVG" style="
                    color: #e88438;
                    border: 1px dotted #e88438;
                    border-radius: 10px;
                  ">
                  <img class="me-1" src="assets/svg/question 1.svg" />
                  <span> Nhấn xem điều kiện</span>
                </h4>
              </span>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center p-3 border-dotted">
            <h6>HSD: {{ voucher.conditions?.end | date : "dd/MM/yyyy" }}</h6>
            <div class="d-flex gap-2">
              <button class="voucher-btn d-flex gap-1 align-items-center" (click)="applyVoucher(voucher)">
                Sử dụng ngay
              </button>
              <button class="voucher-btn d-flex gap-1 align-items-center" (click)="copyToClipboard(voucher)">
                {{ voucher.code }}
                <img src="assets/svg/copy 3.svg" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-3 bg-white rounded-3" *ngIf="!vouchers.length">
      <app-no-data [class]="'no-data-added position-relative'" [description]="'Bạn chưa có mã giảm giá nào'">
      </app-no-data>
    </div>
  </div>
</ng-template>

<app-voucher-detail-modal #detailModal [selectedVoucher]="selectedVoucher"></app-voucher-detail-modal>