import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { Option } from '@/app/shared/interface/common.interface';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-footer-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  isBrowser: boolean = false;

  @Input() data: Option | null;

  constructor(@Inject(PLATFORM_ID) private platformId: any) { 
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
    }
  }
  
  openNewTab(url: string) {
    window.open(url, '_blank');
  }

}
