<section class="service-section">
  <div class="container-fluid-lg container-fluid-md container-fluid-xs">
    <div class="g-3 g-sm-4 row row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-xl-4 row-cols-2" style="background-color: #EBF8ED;">
      <div *ngFor="let service of data; let last = last" class="pe-2">
        <div class="d-lg-flex d-xl-flex d-md-block d-sm-block pb-3 mobile">
          <img [src]="service.image_url" [alt]="service?.title" class="col pe-2 img-fluid"
            style="height: 60px; width: 60px;">
          <div class="service-detail text-theme col-9 border-2 d-flex flex-column auto" [ngClass]="{'border-end': !last}">
            <div class="">
              <h4 class="fw-bold pt-1">{{ service?.title }}</h4>
            </div>
            <div class="mt-auto">
              <h6 class="text-content">{{ service?.description}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>