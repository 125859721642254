import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  Output,
  NgZone,
  afterNextRender,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import {
  Product,
} from '@/app/shared/interface/product.interface';
import * as data from '@/app/shared/data/owl-carousel';
import { } from '@angular/core';
// import $ from 'jquery';
import { SlidesOutputData } from 'ngx-owl-carousel-o';
import { FlashSaleService } from '@/app/shared/services/flashsale.service';
import { Flashsale } from '../../interface/flashsale.interface';
import { isPlatformBrowser } from '@angular/common';

declare var $: any;
@Component({
  selector: 'app-flashsale-home',
  templateUrl: './flashsale-home.component.html',
  styleUrl: './flashsale-home.component.scss',
})
export class FlashsaleHomeComponent {
  //count down
  date: any;
  now: any;

  targetDate: any = new Date();
  targetTime: any;
  difference: number;
  //flashsale data
  currentstartdate: any = new Date();
  currentenddate: any = new Date();
  public products: Product[] = [];
  //tyle honmepage or page
  @Input() flashsale: Flashsale;
  @Input() type: number = 0;

  //object data
  public isDisplay: boolean = true;
  public title: string = '';
  public countingTitle: string = '';
  public skeletonLoader: boolean = true;
  public skeletonItems = Array.from({ length: 40 }, (_, index) => index);
  public gridClass: string =
    'row g-sm-4 g-3 g-xl-4 g-lg-4 row-cols-xxl-6 row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2 product-list-section m-auto';
  public gridCol: string;
  public active = '';
  public content = '';
  public datatabs = [
    {
      number: 1,
      title: 'Diễn ra từ ngày ',
      name: '22/12 - 24/12',
      value: 'current',
      titlebutton: 'Đang diễn ra',
      id: 'current',
      class:
        'col-xxl-12 col-xl-12 d-flex text-content-center justify-content-right',
      display: true,
    },
    {
      number: 2,
      title: 'Kết thúc sau ',
      name: '25/12 - 26/12',
      value: 'next',
      titlebutton: 'Sắp diễn ra',
      id: 'next',
      class:
        'col-xxl-12 col-xl-12 d-flex text-content-center justify-content-left',
      display: true,
    },
  ];
  //#region countdowwn
  @ViewChild('hours', { static: true }) hours: ElementRef;
  @ViewChild('minutes', { static: true }) minutes: ElementRef;
  @ViewChild('seconds', { static: true }) seconds: ElementRef;
  //#endregion

  //carousel product
  public count: number = 0;
  public product: Product;
  public currentproduct: Product[] = [];
  public nextproduct: Product[] = [];
  public viewproducts: Product[] = [];
  public productSimilartSlider = data.productFlashSaleSlider;
  public offset: number[] = [];
  activeSlide: number = 0;
  //end carousel product

  interval: any;

  isBrowser: boolean = false;

  constructor(
    private flashsaleservice: FlashSaleService,
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { 
    afterNextRender(() => {
      this.zone.runOutsideAngular(() => {
        this.interval = setInterval(() => {
          this.tickTock();
          // this.difference = this.targetTime - this.now;
          // this.difference = this.difference / (1000 * 60 * 60 * 24);
        }, 1000);
      });
    });
  }

  ngOnInit() {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.getFlashSale();
  }
  //#region count down

  tickTock() {
    this.date = new Date();
    if (this.targetDate >= this.date) {
      this.difference = this.targetTime - this.date;
      this.difference = this.difference / (1000 * 60); // Convert milliseconds to minutes

      this.hours.nativeElement.innerText = this.padNumber(Math.floor(this.difference / 60)); // Get the hours component
      this.minutes.nativeElement.innerText = this.padNumber(Math.floor(this.difference % 60)); // Get the minutes component
      this.seconds.nativeElement.innerText = this.padNumber(Math.floor((this.difference * 60) % 60)); // Get the seconds component
    } else if(this.flashsale.currentPeriod.name != '' || this.flashsale.nextPeriod.name != ''){
      this.active = this.datatabs.find((a) => a.display = true)!.id;
      $('#' + this.active).addClass('button-active');
      this.getFlashSale();
    }else if(this.count == 0){
        this.count = 1
        this.getFlashSale();
  }
};

  private padNumber(number: number): string {
    return number.toString().padStart(2, '0');
  }
  //#endregion
  changeActive(event: any) {
    if (this.isBrowser) {
      $('#' + this.active).removeClass('button-active');
      this.active = event.id;
      $('#' + this.active).addClass('button-active');
      // this.active = event.id;
      this.content = event.value;
      this.activeSlide = 0;
      if (this.active == 'next') {
        this.viewproducts = this.nextproduct;
        this.title = 'Sắp diễn ra ' + this.datatabs[1].name;
        this.countingTitle = 'Bắt đầu sau';
        this.targetDate = new Date(this.flashsale.nextPeriod.startDate);
        this.targetTime = this.targetDate.getTime();
      } else {
        this.viewproducts = this.currentproduct;
        this.title = 'Đang diễn ra ' + this.datatabs[0].name;
        this.countingTitle = 'Kết thúc sau';
        this.targetDate = new Date(this.flashsale.currentPeriod.endDate);
        this.targetTime = this.targetDate.getTime();
      }
      this.loadFlashSale(this.viewproducts, this.activeSlide);
    }
  }

  changeActiveAfterTimeZero(change: any) {
    if (this.isBrowser) {
      $('#' + this.active).addClass('button-active');
      this.content = change.value;
      this.activeSlide = 0;
      if (this.active == 'next') {
        this.viewproducts = this.nextproduct;
        this.title = 'Sắp diễn ra ' + this.datatabs[1].name;
        this.countingTitle = 'Bắt đầu sau';
        this.targetDate = new Date(this.flashsale.nextPeriod.startDate);
        this.targetTime = this.targetDate.getTime();
      } else {
        this.viewproducts = this.currentproduct;
        this.title = 'Đang diễn ra ' + this.datatabs[0].name;
        this.countingTitle = 'Kết thúc sau';
        this.targetDate = new Date(this.flashsale.currentPeriod.endDate);
        this.targetTime = this.targetDate.getTime();
      }
      this.loadFlashSale(this.viewproducts, this.activeSlide);
    }
  }

  //#region product flashsale

  getFlashSale(tab?: number) {

    this.flashsaleservice.getFlashSaleEvents.subscribe({
    // this.flashsaleservice.getFlashSale().subscribe({
      next: (res: Flashsale) => {
        if (res) {
          this.flashsale = res;
          if (this.flashsale && (this.flashsale.currentPeriod.products.length > 0 || this.flashsale.nextPeriod.products.length > 0)) {
            this.skeletonLoader = false;
            //data button

            //save dat
            this.currentstartdate = new Date(this.flashsale.currentPeriod.startDate);
            this.currentstartdate = this.currentstartdate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' });
            this.currentenddate = new Date(this.flashsale.currentPeriod.endDate);
            this.currentenddate = this.currentenddate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' });
            this.datatabs[0].name = this.currentstartdate + ' - ' + this.currentenddate;
            this.title = 'Đang diễn ra ' + this.datatabs[0].name;
            this.countingTitle = 'Kết thúc sau ';

            this.currentstartdate = new Date(this.flashsale.nextPeriod.startDate);
            this.currentstartdate = this.currentstartdate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' });
            this.currentenddate = new Date(this.flashsale.nextPeriod.endDate);
            this.currentenddate = this.currentenddate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' });
            this.datatabs[1].name = this.currentstartdate + ' - ' + this.currentenddate;
            if (this.flashsale.currentPeriod.name == '') {
              this.datatabs[0].display = false;
            }
            if (this.flashsale.nextPeriod.name == '') {
              this.datatabs[1].display = false;
            }

            this.nextproduct = this.flashsale.nextPeriod.products;
            this.currentproduct = this.flashsale.currentPeriod.products;
            this.viewproducts = this.currentproduct;
            if (this.flashsale.currentPeriod.name != '') {
              this.targetDate = new Date(this.flashsale.currentPeriod.endDate);
              this.targetTime = this.targetDate.getTime();
            }
            if (this.flashsale.currentPeriod.name == '' && this.flashsale.nextPeriod.name != '') {
              this.title = 'Sắp diễn ra ' + this.datatabs[1].name;
              this.countingTitle = ' Bắt đầu sau ';
              this.targetDate = new Date(this.flashsale.nextPeriod.startDate);
              this.targetTime = this.targetDate.getTime();
            }

            this.active = this.datatabs.find((a) => a.display == true)!.id;
            if (this.isBrowser) {
              $('#' + this.active).addClass('button-active');
              this.changeActiveAfterTimeZero(this.active);
            }

            //push data to carousel
            this.loadFlashSale(this.viewproducts, this.activeSlide);
            this.isDisplay = true;
          }
          else {
            this.datatabs[0].display = false;
            this.datatabs[1].display = false;
            this.isDisplay = false;
            this.targetTime = this.targetDate.getTime();
            this.viewproducts = []
            this.title = '';
            this.countingTitle = '';
            this.loadFlashSale(this.viewproducts, this.activeSlide);
          }

        }

      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  loadFlashSale(productList: Product[], index: number) {
    this.skeletonLoader = true;
    const limit = 12;
    this.products = [];
    //set dot
    this.offset = [];
    for (let i = 0; i < productList.length / limit; i++) {
      this.offset.push(i);
    }
    for (let i = 0 + index * limit; i < limit + index * limit; i++) {
      if (productList[i]) {
        this.products.push(productList[i]);
      }
      // this.products.push(productList[i]);
    }
    this.skeletonLoader = false;

  }

  getPassedData(data: SlidesOutputData) {
    if (data.slides!.length > 0) {
      this.activeSlide = data.startPosition!;
      this.loadFlashSale(this.viewproducts, this.activeSlide);
    }
  }
  ngOnDestroy(){
    clearInterval(this.interval);
  }
  //#endregion
}
