<!-- Paris Theme-->
<div class="container-fluid-lg container-fluid-md container-fluid-xs">
  <div class="row g-4">
    <div class="col-xl-8 ratio_65 mt-0" *ngIf="data?.main_banner?.length > 1">
      <div class="home-contain h-100 skeleton-banner-xl">
        <owl-carousel-o [options]="bannerSlider" class="p-0 m-0">
          <ng-container carouselSlide *ngFor="let banner of data?.main_banner">
            <ng-template carouselSlide>
              <div *ngIf="banner.redirect_link.link">
              <a routerLink="{{ banner.redirect_link.link }}">
                <div class="home-banner-contain">
                  <img
                    class="b-cl"
                    [src]="banner.image_url"
                    style="
                      min-height: 445px;
                      max-height: 445px;
                      width: 100%;
                      object-fit: cover;
                      border-radius: 10px;
                    "
                  />
                </div>
              </a>
            </div>
            <div *ngIf="!banner.redirect_link.link">
                <div class="home-banner-contain">
                  <img
                    class="b-cl"
                    [src]="banner.image_url"
                    style="
                      min-height: 445px;
                      max-height: 445px;
                      width: 100%;
                      object-fit: cover;
                      border-radius: 10px;
                    "
                  />
                </div>
            </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
    <div
      class="col-xl-4 ratio_65 mt-0 d-none d-lg-block d-xl-block"
      *ngIf="data?.sub_banner_1?.image_url || data?.sub_banner_2?.image_url"
    >
      <div class="row g-4">
        <div
          class="col-xl-12 col-md-6 skeleton-banner-sm"
          *ngIf="data?.sub_banner_1?.image_url && data?.sub_banner_1.redirect_link.link"
        >
          <a [routerLink]="data?.sub_banner_1.redirect_link.link">
            <div class="home-contain" style="max-height: 15rem">
              <div class="skeleton-text-wrap">
                <span class="placeholder col-7"></span>
                <span class="placeholder col-5"></span>
                <span class="placeholder col-4"></span>
                <span class="placeholder col-6"></span>
              </div>
              <img
                class="b-cl"
                [src]="data?.sub_banner_1?.image_url"
                style="
                  height: 100%;
                  width: 100%;
                  min-height: 209px;
                  max-height: 209px;
                  object-fit: cover;
                  border-radius: 10px;
                "
              />
            </div>
          </a>
        </div>
        <div
        class="col-xl-12 col-md-6 skeleton-banner-sm"
        *ngIf="data?.sub_banner_1?.image_url && !data?.sub_banner_1.redirect_link.link"
      >
          <div class="home-contain" style="max-height: 15rem">
            <div class="skeleton-text-wrap">
              <span class="placeholder col-7"></span>
              <span class="placeholder col-5"></span>
              <span class="placeholder col-4"></span>
              <span class="placeholder col-6"></span>
            </div>
            <img
              class="b-cl"
              [src]="data?.sub_banner_1?.image_url"
              style="
                height: 100%;
                width: 100%;
                min-height: 209px;
                max-height: 209px;
                object-fit: cover;
                border-radius: 10px;
              "
            />
          </div>
      </div>
        <div
          class="col-xl-12 col-md-6 skeleton-banner-sm"
          *ngIf="data?.sub_banner_2?.image_url && data?.sub_banner_2?.redirect_link.link"
        >
          <a [routerLink]="data?.sub_banner_2.redirect_link.link">
            <div class="home-contain" style="max-height: 15rem">
              <div class="skeleton-text-wrap">
                <span class="placeholder col-7"></span>
                <span class="placeholder col-5"></span>
                <span class="placeholder col-4"></span>
                <span class="placeholder col-6"></span>
              </div>
              <img
                class="b-cl"
                [src]="data?.sub_banner_2?.image_url"
                style="
                  height: 100%;
                  width: 100%;
                  min-height: 209px;
                  max-height: 209px;
                  object-fit: cover;
                  border-radius: 10px;
                "
              />
            </div>
          </a>
        </div>
        <div
        class="col-xl-12 col-md-6 skeleton-banner-sm"
        *ngIf="data?.sub_banner_2?.image_url && !data?.sub_banner_2?.redirect_link.link"
      >
          <div class="home-contain" style="max-height: 15rem">
            <div class="skeleton-text-wrap">
              <span class="placeholder col-7"></span>
              <span class="placeholder col-5"></span>
              <span class="placeholder col-4"></span>
              <span class="placeholder col-6"></span>
            </div>
            <img
              class="b-cl"
              [src]="data?.sub_banner_2?.image_url"
              style="
                height: 100%;
                width: 100%;
                min-height: 209px;
                max-height: 209px;
                object-fit: cover;
                border-radius: 10px;
              "
            />
          </div>
      </div>
      </div>
    </div>
  </div>
</div>
