<!-- Search Basic Style -->
<div class="input-group" *ngIf="style == 'basic'">
  <input
    type="search"
    class="form-control"
    placeholder="Search here.."
    [formControl]="term"
  />
  <app-button
    [type]="'button'"
    [class]="'btn'"
    [spinner]="false"
    [id]="'basic_search_btn'"
    (click)="redirectToSearch()"
  >
    <i class="ri-search-line"></i>
  </app-button>
</div>
<!-- Search Basic Style -->
<div class="input-group" *ngIf="style == 'berlin'">
  <a href="javascript:void(0)" class="btn" (click)="toggleSearchBox()">
    <i class="ri-search-line"></i>
  </a>
</div>
<!-- Search Classic Style -->
<div
  class="searchbar-box-2 input-group d-xl-flex d-none"
  *ngIf="style == 'classic'"
>
  <app-button
    [type]="'button'"
    [class]="'btn search-icon'"
    [spinner]="false"
    [id]="'classic_search_btn'"
    (click)="redirectToSearch()"
  >
    <i class="ri-search-line"></i>
  </app-button>
  <input
    type="text"
    class="form-control"
    placeholder="Bạn đang tìm sản phẩm gì ?"
    [formControl]="term"
  />
  <app-button
    [type]="'button'"
    [class]="'btn search-button'"
    [spinner]="false"
    [id]="'classic_search_text_btn'"
    (click)="redirectToSearch()"
  >
    {{ "search" | translate }}
  </app-button>
</div>
<!-- Search Standard Style -->
<div *ngIf="style == 'standard'">
  <input
    id="searchInput"
    type="search"
    class="form-control border-0"
    placeholder="Bạn đang tìm sản phẩm gì ?"
    [(ngModel)]="inputText"
    (keyup)="input($event)"
    (focus)="onSearchInputFocus()"
  />
  <app-button
    [type]="'button'"
    [class]="'btn search-button'"
    [spinner]="false"
    [id]="'standard_search_btn'"
    (click)="redirectToSearch()"
    style="right: 5px"
  >
    <i class="ri-search-line"></i>
  </app-button>
</div>

<div class="dropdown-list d-block" *ngIf="showSearchResult">
  <ul>
    <li *ngFor="let item of items">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <a
          class="w-100"
          [routerLink]="['/product/', item.slug]"
          (click)="toggleSearchBox()"
        >
          {{ item.name }}</a
        >
        <button
          *ngIf="isAuthenticated && isAccountActivated && item.basePrice > 0"
          class="btn-cart"
          (click)="addToCart(item)"
        >
          <i class="ri-shopping-cart-line"></i>
        </button>
      </div>
    </li>

    <ng-container *ngIf="!itemsExtend.length && items.length < body.limit; else productContent">
      <div class="d-block">
        <div class="skeleton-div">
          <div class="product-box skeleton-box bg-transparent">
            <div class="skeleton">
              <div class="skeleton__p w-100"></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #productContent>
      <div *ngIf="itemsExtend.length > 0 && items.length < body.limit">
        <li>
          <span class="fw-bold">Có thể bạn muốn tìm</span>
        </li>
        <li *ngFor="let ext of itemsExtend">
          <app-loader *ngIf="loaderStatus"></app-loader>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <a
              class="w-100"
              [routerLink]="['/product/', ext.slug]"
              (click)="toggleSearchBox()"
            >
              {{ ext.name }}</a
            >
            <button
              *ngIf="isAuthenticated && isAccountActivated && ext.basePrice > 0"
              class="btn-cart"
              (click)="addToCart(ext)"
            >
              <i class="ri-shopping-cart-line"></i>
            </button>
          </div>
        </li>
      </div>
    </ng-template>
  </ul>
</div>

<!-- Search Full -->
<div class="search-full" [class.open]="show">
  <div class="input-group">
    <span class="input-group-text">
      <i class="ri-search-line font-light"></i>
    </span>
    <input
      type="text"
      class="form-control search-type"
      placeholder="Search here.."
      [formControl]="term"
    />
    <span class="input-group-text close-search" (click)="toggleSearchBox()">
      <i class="ri-close-line"></i>
    </span>
  </div>
</div>
