import { Component } from '@angular/core';

@Component({
  selector: 'app-partner-logo-svg',
  templateUrl: './partner-logo-svg.component.html',
  styleUrl: './partner-logo-svg.component.scss'
})
export class PartnerLogoSvgComponent {

}
