import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { PointUsage, User } from '@/app/shared/interface/user.interface';
import { AccountService } from '@/app/shared/services/account.service';
import { GiftService } from '@/app/shared/services/gift.service';
import { NgbDate, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { QueryStringService } from '@/app/shared/services/querystring.service';
import { ItemsPageQueryString, PageQueryString } from '@/app/shared/filter';
import { PaginationModel } from '@/app/shared/interface/product.interface';

@Component({
  selector: 'app-gift',
  templateUrl: './gift.component.html',
  styleUrls: ['./gift.component.scss']
})


export class GiftComponent {

  private destroy$ = new Subject<void>();

  public user: User;

  giftList: PointUsage[] = [];

  headerColumns = [
    "Thời gian",
    "Nội dung",
    "Số lá xanh",
    "Mã đơn hàng"
  ];

  giftInfo: any;

  public startTime: any = {
    date: {} as NgbDate,
    time: {} as NgbTimeStruct,
  };
  public endTime: any = {
    date: {} as NgbDate,
    time: {} as NgbTimeStruct,
  }
  private limit: number = 15;
  searchBody: {
    limit: number,
    offset: number,
    fromDate?: Date,
    toDate?: Date,
  } = {
      limit: this.limit,
      offset: 0
    };

  private changeQueryStringSubcription?: Subscription;

  constructor(
    private accountService: AccountService,
    private toastService: ToastrService,
    private giftService: GiftService,
    private domSanitizer: DomSanitizer,
    private queryStringService: QueryStringService,
  ) { }

  ngOnInit() {
    this.accountService.getUserData.subscribe({
      next: (user: User) => {
        if (user && user.userName) {
          this.user = user;
          this.getGiftInfo();
          this.queryStringService.startFetchingData();
        }
      }
    });

    this.changeQueryStringSubcription = this.queryStringService.eventChangeQueryString.subscribe(() => {
      this.getGiftHistoryList();
    });

  }

  getGiftHistoryList() {
    let body = this.queryStringService.buildFetchingModel(
      this.searchBody.limit
    );
    this.searchBody.limit = body.limit ?? this.limit;
    this.searchBody.offset = body.offset ?? 0;

    this.giftService.getGiftHistoryList(JSON.stringify(this.searchBody)).subscribe({
      next: (res: any) => {
        if (res && res.data) {
          this.giftList = res.data;

          let pagination: PaginationModel = {
            limit: body.limit,
            offset: body.offset,
            searchTerm: body.searchTerm,
            totalItems: res.total,
          };
          this.queryStringService.changePagination(pagination);
        }
      }
    });
  }

  getGiftInfo() {
    this.giftService.getGiftInfo.subscribe({
      next: (res: any) => {
        if (res) {
          this.giftInfo = res;
        }
      }
    });
  }

  search() {
    let toDay = new Date();
    this.searchBody = {
      limit: this.limit,
      offset: 0,
    };

    if (this.startTime.date?.year && !this.endTime.date?.year) {
      this.searchBody.fromDate = new Date(
        this.startTime.date?.year,
        this.startTime.date?.month - 1,
        this.startTime.date?.day,
        0, 0, 0, 0
      );

      this.searchBody.toDate = new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(),
        23, 59, 59, 999
      );
    }

    if (this.endTime.date?.year && !this.startTime.date?.year) {
      this.searchBody.toDate = new Date(
        this.endTime.date?.year,
        this.endTime.date?.month - 1,
        this.endTime.date?.day,
        23, 59, 59, 999
      );
      this.searchBody.fromDate = new Date(1, 1, 1);

    }

    if (this.startTime.date?.year && this.endTime.date?.year) {
      this.searchBody.fromDate = new Date(
        this.startTime.date?.year,
        this.startTime.date?.month - 1,
        this.startTime.date?.day,
        0, 0, 0, 0
      );

      this.searchBody.toDate = new Date(
        this.endTime.date?.year,
        this.endTime.date?.month - 1,
        this.endTime.date?.day,
        23, 59, 59, 999
      );

      if (this.searchBody.fromDate! > this.searchBody.toDate!) {
        this.toastService.error('Ngày bắt đầu và kết thúc không hợp lệ!', '', { positionClass: 'toast-top-right' });
        return;
      }
    }

    this.queryStringService.changeQueryString([new PageQueryString(1), new ItemsPageQueryString(this.searchBody.limit)]);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.changeQueryStringSubcription?.unsubscribe();
  }

}
