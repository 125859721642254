import { Affiliate, User } from '@/app/shared/interface/user.interface';
import { AccountService } from '@/app/shared/services/account.service';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-loyalty',
  templateUrl: './loyalty.component.html',
  styleUrl: './loyalty.component.scss'
})
export class LoyaltyComponent {

  user: User;

  summary: any[] = [
    {
      title: "Tổng 1",
      value: 0,
      icon: "assets/svg/gift.svg"
    },
    {
      title: "Tổng 2",
      value: 0,
      icon: "assets/svg/gift.svg"
    }
  ];

  totalPoint: number = 0;

  constructor(
    private accountService: AccountService,
    private toastService: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit() {
    this.getLoyalty();
  }

  getLoyalty() {
    this.accountService.getAffiliate().subscribe(
      (res: Affiliate) => {
        this.summary[0].value = res.directAffiliatePoint;
        this.summary[1].value = res.indirectAffiliatePoint;
        this.totalPoint = res.availableAffiliatePoint;
      },
      (error) => {
        this.toastService.error(error.error.message);
      }
    );
  }
}
