<ng-template #loginModal let-modal>
  <div class="modal-header justify-content-center">
    <h3 class="fw-semibold">Đăng nhập</h3>
    <app-button
      [class]="'btn btn-close btn-sm'"
      [spinner]="false"
      [id]="'login_modal_close_btn'"
      (click)="modal.dismiss('Cross click')"
    >
      <i class="ri-close-line"></i>
    </app-button>
  </div>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="modal-body address-form">
      <div class="row justify-content-center text-center">
        <div class="col">
          <p
            >Vui lòng đăng nhập để hưởng những đặc quyền dành cho thành viên.
          </p>
        </div>
      </div>
      <div class="row justify-content-center p-2">
        <div class="row">
          <div class="">
            <input
              type="text"
              class="form-control"
              id="username"
              formControlName="username"
              placeholder="Nhập số điện thoại"
            />
            <!-- <label for="username">{{ "email_address" | translate }}</label> -->

            <div
              *ngIf="form.controls['username'].touched && form.controls['username'].errors?.['required']"
              class="invalid-feedback"
            >
              <!-- {{ "email_is_required" | translate }} -->
              Nhập Số điện thoại
            </div>
            <div
              *ngIf="form.controls['username'].touched && form.controls['username'].errors?.['username']"
              class="invalid-feedback"
            >
              <!-- {{ "invalid_email" | translate }} -->
              Số điện thoại không đúng
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center p-2">
        <div class="row d-flex">
          <div class="input-group">
            <input #x
              type="password"
              class="form-control"
              id="password"
              formControlName="password"
              placeholder="Nhập mật khẩu"
              style="border-right: none;"
            />
            <span class="input-group-text" style="background: none;" (click)="x.type=x.type=='password'?'text':'password'"><i class="ri-eye-line"></i></span>
            <div
              *ngIf="form.controls['password'].touched && form.controls['password'].errors?.['required']"
              class="invalid-feedback"
            >
              Nhập mật khẩu
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-floating log-in-form"></div>
      </div>
      <div class="col-12">
        <div class="form-floating theme-form-floating log-in-form"></div>
      </div>
      <div class="row justify-content-center p-2">
        <div class="col-12 text-center">
          <app-button [id]="'login_btn'">Đăng Nhập</app-button>
        </div>
      </div>
      <div class="row justify-content-center p-2">
        <div class="col-12 text-center">
          <a href="javascript:void(0);" (click)="handleForgotPasswordModal($event)"><strong>Quên mật khẩu ?</strong></a>
        </div>
      </div>
      <div class="row justify-content-center p-2">
        <div class="col-12 text-center">
          <p>Chưa có tài khoản <a href="javascript:void(0);" (click)="handleButtonClick($event)">Đăng ký tại đây</a></p>
        </div>
      </div>
    </div>
  </form>
</ng-template>
