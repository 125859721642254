import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { Province } from "../interface/province.interface";
import { DeliveryAddressModel } from "../interface/user.interface";

@Injectable({
  providedIn: "root",
})
export class AddressService {

  constructor(private http: HttpClient) {}

  deleteUserAddress(id: number) {
    return this.http.delete<any>(`${environment.baseURL}provinces`);
  }

  updateUserAddress(address: any) {
    return this.http.put<any>(`${environment.baseURL}user/address`, address,
    { headers: { 'Content-Type': 'application/json' } } );
  }

  getUserAddress() {
    return this.http.get<DeliveryAddressModel>(`${environment.baseURL}user/address`);
  }

  createUserAddress(address: any) {
    return this.http.post<any>(`${environment.baseURL}user/address`, address,
    { headers: { 'Content-Type': 'application/json' } }
    );

  }
}
