<section class="service-section" style="background-color: #F5F6F8;
">
  <div class="container-fluid-lg container-fluid-md container-fluid-xs mb-4" >
    <div class="text-theme justify-content-left d-flex">
      <h2 class="text-center me-2">
        <strong class="fw-bold">{{ data.header }}</strong>
        <div style="background-color: #ffc803; border-radius: 5px; height: 5px;"></div>
      </h2>
      <h3>
        <strong class="fw-bold">{{ data.header2 }}</strong>
      </h3>
    </div>
  </div>
  <div>
    <!-- Banner Horizontal Style-->
    <div
      class="container-fluid-lg container-fluid-md container-fluid-xs"
      *ngIf="banners?.length && style == 'horizontal'"
    >
      <div class="banner-slider">
        <owl-carousel-o [options]="bannerSlider">
          <ng-container carouselSlide *ngFor="let banner of banners">
            <ng-container *ngIf="banner?.status">
              <ng-template carouselSlide>
                <div class="banner-contain border border-2 border-theme bg-white p-4 ms-5 me-5">
                  <div class="row px-4 div-row">
                    <div class="col-9 justify-content-center d-flex flex-column">
                      <div class="text-theme banner-name">
                        <strong>{{ banner.name }}</strong>
                      </div>
                      <div class="banner-detail">
                        {{ banner.detail }}
                      </div>
                    </div>
                    <div class="col-3">
                      <img
                        class="banner-avatar"
                        src="{{ banner.image_url }}"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="px-4 mt-3 banner-feedback">
                    <img src="assets/images/home/quotestart.png" alt="">
                    {{banner.feedback}}
                    <img class="d-inline" src="assets/images/home/quoteend.png" alt="">
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
