import { Cart, CartItem } from '@/app/shared/interface/cart.interface';
import { Product } from '@/app/shared/interface/product.interface';
import { User } from '@/app/shared/interface/user.interface';
import { AuthService } from '@/app/shared/services/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-exchange-gift-modal',
  templateUrl: './exchange-gift-modal.component.html',
  styleUrl: './exchange-gift-modal.component.scss'
})
export class ExchangeGiftModalComponent {

  @ViewChild("exchangeGiftModal", { static: false }) exchangeGiftModal: TemplateRef<any>;

  @Input() giftList: Product[] = [];
  @Input() cart: Cart;
  @Input() giftInfo: any;

  public closeResult: string;
  public modalOpen: boolean = false;

  isAuthenticated: boolean = false;

  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.isAuthenticated = this.authService.isAuthenticated();
  }

  async openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalOpen = true;
      this.modalService.open(this.exchangeGiftModal, {
        ariaLabelledBy: 'Confirmation-Modal',
        centered: true,
        windowClass: 'theme-modal text-center exchange-gift-modal modal-lg'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: ModalDismissReasons): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  close() {
    this.modalService.dismissAll();
  }

  updateUserGiftPoint(event: number) {
    this.cart.totalPoint = event;
  }
}
