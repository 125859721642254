<!-- WEB -->
<div class="d-none d-lg-block d-xl-block">
  <div class="row pb-3">
    <div class="col-xxl-7 col-lg-7 col-md-12 col-sm-12 p-0">
      <div class="p-3 px-4 bg rounded-3 box-shadow text-white d-flex align-items-center justify-content-between">
        <div>
          <h5 class="pb-3">Tổng quà tặng</h5>
          <div class="d-flex gap-1 gap-2">
            <h2 class="fw-bolder">
              {{totalPoint}}
            </h2>
          </div>
        </div>
        <div class="d-flex">
          <img src="assets/svg/coin.svg" style="width: 5rem; height: 4rem;" />
        </div>
      </div>
    </div>
    <div class="col-xxl-5 col-lg-5 col-md-12 col-sm-12 d-flex gap-3 ps-3">
      <div *ngFor="let sum of summary; let i = index" class="col-xxl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="d-grid gap-2" [class]="i > 1 ? 'border-gradient-white' : 'border-gradient'">
          <div class="d-flex gap-1">
            <img [src]="sum.icon" style="width: 2rem; height: 2rem;" />
            <h5>{{sum.title}}</h5>
          </div>
          <div class="d-flex align-items-baseline gap-2">
            <h2 class="text-center fw-bold theme-color" [style]="i < 2 ? 'font-size: 2rem;' : 'font-size: 1.25rem;'">
              {{sum.value.toLocaleString('vi-VN')}}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MOBILE -->
<div class="d-md-block d-sm-block d-lg-none d-xl-none bg-transparent">
  <div class="row">
    <div class="col-xxl-7 col-lg-7 col-md-12 col-sm-12 pb-3">
      <div class="p-3 px-4 bg rounded-3 box-shadow text-white d-flex align-items-center justify-content-between">
        <div>
          <h5 class="pb-3">Tổng quà tặng</h5>
          <div class="d-flex gap-1 align-items-baseline gap-2">
            <h2 class="fw-bolder">
              0
            </h2>
          </div>
        </div>
        <div class="d-flex">
          <img src="assets/svg/coin.svg" style="width: 5rem; height: 4rem;" />
        </div>
      </div>
    </div>
  </div>
  <div class="col-xxl-12 col-lg-12 col-md-12 col-sm-12 d-grid gap-4 pb-3 bg-white rounded-3">
    <div class="d-grid gap-3">
      <div class="pb-4 g-3 g-sm-4 row row-cols-2 row-cols-lg-2 row-cols-xxl-4">
        <div *ngFor="let sum of summary; let i = index" class="col-xxl-6 col-lg-6 col-md-12 col-sm-12">
          <div class="d-grid gap-2" [class]="i > 1 ? 'border-gradient-white' : 'border-gradient'">
            <div class="d-flex gap-1">
              <img [src]="sum.icon" style="width: 2rem; height: 2rem;" />
              <h5>{{sum.title}}</h5>
            </div>
            <div class="d-flex gap-2">
              <h2 class="text-center fw-bold theme-color" [style]="i < 2 ? 'font-size: 2rem;' : 'font-size: 1.25rem;'">
                {{sum.value.toLocaleString('vi-VN')}}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>