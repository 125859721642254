import { Component, Input, ViewChild, afterNextRender } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { WService } from '@/app/shared/interface/common.interface';
// import $ from 'jquery';
import { RegistryModalComponent } from "@/app/shared/widgets/registry-modal/registry-modal.component";
import { LoginModalComponent } from "@/app/shared/widgets/login-modal/login-modal.component";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { after } from 'node:test';

declare var $: any;

@Component({
  selector: 'app-registry-info-home',
  templateUrl: './registry-info-home.component.html',
  styleUrl: './registry-info-home.component.scss',
  animations: [
    trigger('wipeIn', [
      state('right', style({
        transform: 'translateX(0%)',
        opacity: 1
      })),
      state('left', style({
        transform: 'translateX(-0%)',
        opacity: 0
      })),
      transition('right => left', animate('0.3s ease-in-out')),
      transition('left => right', animate('0.3s ease-in-out'))
    ])
  ]
})
export class RegistryInfoHomeComponent {

  public active = 'pin1' as string;
  public content: SafeHtml;
  animationState: string = 'right';

  @ViewChild("registryModal") RegistryModal: RegistryModalComponent;
  @ViewChild("loginModal") LoginModal: LoginModalComponent;
  public datatabs = [
    {
      number: "1",
      name: "TẠO TÀI KHOẢN",
      value: "<div class='row pt-4 pb-4'style='font-size: 22px;'><span><strong>Điền đầy đủ thông tin doanh nghiệp và cung cấp các loại hồ sơ sau:</strong></span></div><div class='row ps-2 pt-1 d-flex'style='font-size: 22px;'><div class='d-flex align-items-center'><img class='pe-2' src='assets/images/home/checkedhome.png'/><div class=''>Mã số thuế</div></div></div><div class='row ps-2 pt-1'style='font-size: 22px;'><div class='d-flex align-items-center'><img class='pe-2' src='assets/images/home/checkedhome.png'/>Giấy phép đăng ký kinh doanh</div></div><div class='row ps-2 pt-1 d-flex justify-content-center'style='font-size: 22px;'><span class='d-flex align-items-center'><img class='pe-2' src='assets/images/home/checkedhome.png'/>Giấy chứng nhận đạt chuẩn GPP</span></div><div class='row ps-2 pt-1'style='font-size: 22px;'><span class='d-flex align-items-center'><img class='pe-2' src='assets/images/home/checkedhome.png'/>Giấy phép đăng ký kinh doanh dược</span></div><div class='row text-theme pt-1'style='font-size: 22px;'><span>Đăng kí ngay để xem thêm hơn 10.000 sản phẩm và nhận ưu đãi dành riêng cho bạn!</span></div>",
      id: "pin1",
      display: true
    },
    {
      number: "2",
      name: "KÍCH HOẠT TÀI KHOẢN",
      value: "<div class='row p-4'>Sau khi đăng ký tài khoản, Chuyên viên CSKH của Giá Thuốc Tốt sẽ liên hệ trong vòng 24 giờ để xác nhận thông tin đã cung cấp và kích hoạt tài khoản.</div><div class='row p-4'>Nếu bạn có thắc mắc, vui lòng liên hệ hotline: 0878.929.789 để được hỗ trợ trực tiếp.</div></div>",
      id: "pin2",
      display: true
    },
    {
      number: "3",
      name: "NHẬN ƯU ĐÃI ĐƠN ĐẦU TIÊN",
      value: "<div class='row p-4'>Đặt hàng ngay và nhận voucher cho đơn hàng đầu tiên, giao hàng miễn phí trên toàn quốc.</div><div class='row p-4'>Nếu bạn có thắc mắc, vui lòng liên hệ hotline: 0878.929.789 để được hỗ trợ trực tiếp.</div>",
      id: "pin3",
      display: true
    }
  ]
  constructor(public _sanitizer: DomSanitizer) { 
    afterNextRender(() => {
      $('#' + this.active).addClass('button-active');
    });
  }

  ngOnInit() {
    for (let tab of this.datatabs) {
      tab.value = this._sanitizer.bypassSecurityTrustHtml(tab.value) as string;
    }
    this.active = this.datatabs.find(a => a.value)!.id;

    // this.content = this._sanitizer.bypassSecurityTrustHtml(this.datatabs.find(a => a.value)!.value);
  }
  handleButtonClick(event: Event) {
    // Add your logic for the button click action here
    // For example, you can open a modal:
    console.log(1);
    
    this.RegistryModal.openModal();
  }


  openLoginModal(event: Event) {
    // Add your logic for the button click action here
    // For example, you can open a modal:
    this.LoginModal.openModal();
  }


  changeActive(event: any) {
    this.animationState = 'left';
    setTimeout(() => {
      this.animationState = 'right';

      $('#' + this.active).removeClass('button-active');
      this.active = event.id;
      $('#' + this.active).addClass('button-active');
    }, 300); // Use the same duration as the animation


  }

  transform(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }
}
