import {
  Component,
  TemplateRef,
  ViewChild,
  PLATFORM_ID,
  Inject,
  Output,
  EventEmitter,
  HostListener,
  afterNextRender,
  NgZone,
  ElementRef,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, firstValueFrom, map, Observable, of, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ProvinceService } from '../../services/province.service';
import { AuthService } from '@/app/shared/services/auth.service';
import { CommonService } from '@/app/shared/services/common.service';
import { AccountService } from '@/app/shared/services/account.service';
import { Breadcrumb } from '@/app/shared/interface/breadcrumb';
import { Toast, ToastrService } from 'ngx-toastr';
import { District, Province, Ward } from '../../interface/province.interface';
import { LoginModalComponent } from '@/app/shared/widgets/login-modal/login-modal.component';
import { Select2Data } from 'ng-select2-component';
import { BaseResponse } from '../../interface/common.interface';
import { ReCaptchaV3Service } from 'ng-recaptcha';

enum GenderId {
  Female = 0,
  Male = 1,
  Other = 2
}

declare var $: any;

interface GenderOption {
  value: GenderId;
  label: string;
}

@Component({
  selector: 'app-registry-modal-v2',
  templateUrl: './registry-modal-v2.component.html',
  styleUrl: './registry-modal-v2.component.scss',
})
export class RegistryModalV2Component {
  @Output() openLoginModal: EventEmitter<any> = new EventEmitter();
  @ViewChild('registryModal', { static: false })
  RegistryModal: TemplateRef<string>;
  public bodyCreateUser: {
    UserName: string;
    Password: string;
    Address: {
      Province: Province;
      Status: Number;
    };
    Referer: string;
  };

  public bodyLogin: {
    username: string;
    password: string;
  };

  public form: FormGroup;
  public closeResult: string;
  public modalOpen: boolean = false;
  public tnc = new FormControl(false, [Validators.requiredTrue]);
  public provinceCode: number = 0;
  public districtCode: number = 0;
  public province: Province;
  public provinces: Province[] = [];
  public districts: District[] = [];
  public wards: Ward[] = [];

  select2Provinces: any;
  select2Districts: any;
  select2Wards: any;

  public formOfBusinesses = [
    {
      code: 0,
      name: 'Nhà thuốc',
    },
    {
      code: 1,
      name: 'Quầy thuốc',
    },
    {
      code: 2,
      name: 'Tủ thuốc trạm y tế xã',
    },
    {
      code: 3,
      name: 'Cơ sở bán buôn',
    },
    {
      code: 4,
      name: 'Bệnh viện',
    },
    {
      code: 5,
      name: 'Cơ sở khám chữa bệnh khác',
    },
    {
      code: 6,
      name: 'Khác',
    },
  ];

  step: number = 0;
  isValidatedOTP: boolean = true;
  second: number = 60;
  phoneNo: string = '';
  otp: string = '';
  otpOptions: any = {
    length: 4,
    inputClass: 'input-otp',
    allowNumbersOnly: true,
    containerClass: 'd-flex justify-content-center',
  };
  resendOTPInterval: any;

  private executionSubscription: Subscription;
  private registerSubscription: Subscription;
  private registerSubject = new Subject<any>();

  debounceTimeMs = 500;

  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private commonService: CommonService,
    private toast: ToastrService,
    private router: Router,
    private provinceService: ProvinceService,
    private accountService: AccountService,
    private zone: NgZone,
    private recaptchaV3Service: ReCaptchaV3Service,
    private route: ActivatedRoute
  ) {
    this.form = this.formBuilder.group({
      phone: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^0[0-9]{9}$/)]),
      password: new FormControl('', [
        Validators.required,
        this.checkConfirmPasswordMatch, Validators.minLength(6),
      ]),
      repassword: new FormControl('', [
        Validators.required,
        this.checkPasswordMatch, Validators.minLength(6),
      ]),
      province: new FormControl('', [Validators.required]),
      phoneintroduce: new FormControl(''),
      referer: new FormControl('', [Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^0[0-9]{9}$/)]),
    });
  }

  ngOnInit(): void {
    this.getProvincesInfo();

    this.registerSubscription = this.registerSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe(() =>
      this.next()
    );
    this.route.queryParams.subscribe((params) => {
      if (params['ref']) {
        this.form.get('referer')?.setValue(params['ref']);
      }
    });
  }

  getProvincesInfo() {
    this.provinceService.getProvinceInfoByDepth(this.provinceCode,this.districtCode).subscribe((res: any) => {
      if (res) {
        this.provinces = res;
        this.select2Provinces = this.provinces.map((province: Province) => new Object({
          value: province.code,
          label: province.name
        }));
      }
    });
  }

  //open modal
  async openModal() {
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalOpen = true;
      this.step = 0;
      this.modalService
        .open(this.RegistryModal, {
          ariaLabelledBy: 'register-add-Modal',
          centered: true,
          windowClass: 'theme-modal modal-login modal-dialog-scrollable',
        })
        .result.then(
          (result) => {
            `Result ${result}`;
            this.back();
          },
          (reason) => {
            this.back();
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  private getDismissReason(reason: ModalDismissReasons): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  checkConfirmPasswordMatch(
    control: AbstractControl
  ): Observable<ValidationErrors | null> {
    const confirmPassword = control.parent?.get('repassword')?.value;

    if (confirmPassword && control.value !== confirmPassword) {
      control.parent?.get('repassword')?.setErrors({ notMatch: true });
      return of({ notMatch: true });
    } else if (!control.parent?.get('repassword')?.getError('required')) {
      control.parent?.get('repassword')?.setErrors(null);
      control.setErrors(null);
      return of(null);
    }
    return of(null);
  }

  checkPasswordMatch = (
    control: AbstractControl
  ): Observable<ValidationErrors | null> => {
    const password = control.parent?.get('password')?.value;
    if (password && control.value !== password) {
      control.parent?.get('password')?.setErrors({ notMatch: true });
      return of({ notMatch: true });
    } else if (!control.parent?.get('password')?.getError('required')) {
      control.parent?.get('password')?.setErrors(null);
      control.setErrors(null);
      return of(null);
    }
    return of(null);
  };

  checkRePassword(): boolean {
    const control = this.form.get('password')!;
    const repasswordControl = this.form.get('repassword')!;
    if (control.touched && control.hasError('required')) {
      return false;
    }
    if (repasswordControl.touched && repasswordControl.hasError('required')) {
      return true;
    }
    if (
      control.touched &&
      !control.hasError('required') &&
      (control.hasError('notMatch') || repasswordControl.hasError('notMatch'))
    ) {
      return true;
    }
    return false;
  }

  countdown() {
    this.resendOTPInterval = setInterval(() => {
      this.second--;
      $("#second").text(this.second);
      if (this.second === 0) {
        clearInterval(this.resendOTPInterval);
      }
    }, 1000);
  }

  getOTP(newOTP: boolean = false) {
    let body: any = {
      phoneNo: this.phoneNo,
      // type: 0
    }
    if (newOTP) {
      body.newOTP = true;
    }
    this.executionSubscription = this.recaptchaV3Service.execute('register').subscribe({
      next: (token: string) => {
        body.recaptchaV3Token = token;
        this.accountService.retrieveOTP(JSON.stringify(body)).subscribe({
          next: (res: BaseResponse) => {
            if (res && res.statusCode === 200) {
              this.second = 60;
              this.countdown();
              this.step = 1;
              this.isValidatedOTP = true;
              this.toast.success(res.message, '', {
                positionClass: 'toast-top-right',
                timeOut: 3000,
              });
            }
            else {
              this.toast.error(res.message, '', {
                positionClass: 'toast-top-right',
                timeOut: 3000,
              });
            }
          },
          error: (error: any) => {
            console.log(error);
            this.toast.error('Có lỗi xảy ra. Vui lòng thử lại sau', '', {
              positionClass: 'toast-top-right',
              timeOut: 3000,
            });
          },
        });
      }
    });
  }

  register() {
    this.registerSubject.next(null);
  }

  next() {
    this.form.markAllAsTouched();
    if (
      this.form.get('password')?.value &&
      this.form.get('repassword')?.value &&
      this.form.get('password')?.value == this.form.get('repassword')?.value
    ) {
      this.form.get('password')?.setErrors(null);
      this.form.get('repassword')?.setErrors(null);
    }

    if (this.form.get('phone')?.value == this.form.get('referer')?.value) {
      return;
    }

    if (this.form.invalid) {
      return;
    }
    if (this.tnc.invalid) {
      this.toast.warning('Vui lòng chấp nhận các điều khoản', '', {positionClass: 'toast-top-right', timeOut: 3000,});
      return;
    }
    this.phoneNo = this.form.get('phone')?.value;
    this.accountService.checkUserExist(this.phoneNo).subscribe({
      next: (res: boolean) => {
        if (res) {
          this.toast.error('Số điện thoại đã tồn tại', '', {
            positionClass: 'toast-top-right',
            timeOut: 3000,
          });
        } else {
          this.getOTP();
        }
      },
      error: (error: any) => {
        console.log(error);
        this.toast.error('Có lỗi xảy ra. Vui lòng thử lại sau', '', {
          positionClass: 'toast-top-right',
          timeOut: 3000,
        });
      },
    });
  }

  back() {
    this.step = 0;
    this.second = 60;
    clearInterval(this.resendOTPInterval);
  }

  onOtpChange(e: any) {
    this.otp = e;

    if (this.otp.length === this.otpOptions.length) {
      this.accountService.submitOTP(JSON.stringify({ phoneNo: this.phoneNo, otp: this.otp })).subscribe({
        next: (res: boolean) => {
          if (res) {
            this.isValidatedOTP = true;
            this.submit();
          }
          else {
            this.isValidatedOTP = false;
            // this.toast.error('Mã OTP không đúng, xin vui lòng nhập lại', '', {
            //   positionClass: 'toast-top-right',
            //   timeOut: 3000,
            // });
          }
        },
        error: (error: any) => {
          console.log(error);
          this.toast.error('Có lỗi xảy ra. Vui lòng thử lại sau', '', {
            positionClass: 'toast-top-right',
            timeOut: 3000,
          });
        },
      });
    }
  }

  openLogin() {
    this.modalService.dismissAll();
    this.openLoginModal.emit(true);
  }

  async submit() {
    if (this.form.get('password')?.value) {
      this.form.get('password')?.setErrors(null);
    }
    // Focus on the first invalid field
    const invalidControl = Object.keys(this.form.controls).find(
      controlName => this.form.get(controlName)?.invalid
    );
    if (invalidControl) {
      const invalidField = document.querySelector(`[formControlName="${invalidControl}"]`);
      if (invalidField) {
        (invalidField as HTMLElement).focus();
      }
      return;
    }
    
    if (this.form.valid) {
      var res = await firstValueFrom(
        this.provinceService.getProvinceInfoByDepth(
          this.form.get('province')?.value,0
        )
      );
      if (res) {
        this.province = res[0];
        this.province.districts = [];
      }

      this.bodyCreateUser = {
        Password: this.form.get('password')?.value,
        UserName: this.form.get('phone')?.value.toString(),
        Address: {
          Province: this.province,
          Status: 0,
        },
        Referer: this.form.get('referer')?.value || '',
      };

      this.accountService.createUserV2(
        JSON.stringify(this.bodyCreateUser)
      ).subscribe({
        next: (res: any) => {
          if (res) {
            localStorage.removeItem('ref');
            this.bodyLogin = {
              username: this.form.get('phone')?.value,
              password: this.form.get('password')?.value,
            };
            this.authService.login(JSON.stringify(this.bodyLogin));
            this.toast.success(
              'Cảm ơn quý khách đã tạo tài khoản tại Giá thuốc tốt. Vui lòng hoàn thiện hồ sơ để được kích hoạt tài khoản. Tải lên hồ sơ <a href="/account/my-business-profile">tại đây</a>.',
              'Tài khoản chưa được kích hoạt',
              {
                positionClass: 'toast-top-right',
                timeOut: 5000,
                enableHtml: true,
              }
            );
            this.modalService.dismissAll();
          }
        },
        error: (error) => {
          console.log(error);
          this.toast.error('Kiểm tra lại thông tin! Đã có tài khoản hoặc thông tin đăng ký sai','',{
            positionClass: 'toast-top-right',
            timeOut: 5000,
            enableHtml: true,
          });
        },
      });
    }
  }

  ngOnDestroy() {
    this.executionSubscription?.unsubscribe();
    this.registerSubscription?.unsubscribe();
  }
}
