<!-- Cart Style Classic -->
<a href="javascript:void(0)" class="header-icon swap-icon border-0" (click)="cartStyle == 'cart_sidebar' && cartToggle()"
  *ngIf="!sidebarCartOpen">
  <small class="badge-number text-bg-warning">
    {{ totalQuantity <= 99 ? totalQuantity : '99+'}}
  </small>
  <i class="ri-shopping-cart-2-line text-white fs-3"></i>
</a>

<!-- Cart On Hover -->
<div class="onhover-div" *ngIf="sidebarCartOpen" [ngClass]="sidebarCartOpen ? 'fixed-cart show ' : 'fixed-cart'"
  [class]="sticky ? 'sticky' : ''">
  <!-- <div class="cart-title">
    <h3>Giỏ hàng</h3>
  </div> -->
  <!-- <div class="pere-text-box success-box" *ngIf="cart && cart.items.length > 0">
    <p *ngIf="shippingFreeAmt > cartTotal; else content">
      {{ "spend" | translate }}
      <span class="shipping">{{
        shippingFreeAmt - cartTotal | currencySymbol
      }}</span>
      {{ "more_and_enjoy" | translate }}
      <span class="shipping">{{ "free_shipping" | translate }}!</span>
    </p>
    <ng-template #content>
      <p>
        <span class="shipping">{{ "congratulations" | translate }}!</span>
        {{ "enjoy_free_shipping_on_us" | translate }}!
      </p>
    </ng-template>
    <div
      class="progress"
      [ngClass]="{
        'danger-progress': shippingCal <= 30,
        'warning-progress': shippingCal >= 31 && shippingCal <= 80
      }"
    >
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        aria-valuenow="75"
        aria-valuemin="0"
        aria-valuemax="100"
        [ngStyle]="{ width: shippingCal + '%' }"
      >
        <i class="ri-truck-line"></i>
      </div>
    </div>
  </div> -->
  <ul class="cart-list" *ngIf="cart.items.length > 0">
    <li class="product-box-contain" *ngFor="let cartItem of cart.items">
      <div class="drop-cart col-xs-12">
        <div class="col-xs-3">
          <a href="javascript:void(0)" class="drop-image">
            <img [src]="cartItem.imgUrl" class="img-fluid" [alt]="cartItem.name" />
          </a>
        </div>
        <div class="drop-contain col-xs-9">
          <div class="d-flex">
            <a class="me-auto w-75" [routerLink]="['/product/', cartItem.productID ]">
              <h6>
                {{ cartItem.name }}
              </h6>
            </a>
            <div class="ms-auto">
              <div class="header-button-group">
                <button [class]="'delete-button close_button'" [id]="'cart_item_delete_btn'"
                  (click)="deleteCartItem(cartItem)">
                  <img src="assets/svg/recycle-bin 3.svg" />
                </button>
              </div>
            </div>
          </div>
          <div style="display: flex; align-items: center; justify-content: space-between;" *ngIf="cartItem.basePrice > 0">
            <h5 class="fw-bold" [ngClass]="cartItem.price < cartItem.basePrice?'text-danger':'text-center'">
              <del class="text-content fw-light" *ngIf="cartItem.price < cartItem.basePrice">
                {{ cartItem.basePrice | currencySymbol }}
              </del>
              {{ cartItem.price | currencySymbol }}
            </h5>
            <div class="cart_qty qty-box product-qty">
              <div class="input-group h-100">
                <app-counter [cartItem]="cartItem" style="width: 100%;"></app-counter>
              </div>
            </div>
          </div>
          <div style="display: flex; align-items: center; justify-content: space-between;" class="text-center" *ngIf="cartItem.basePrice <= 0">
            <div class="out-of-stock-label">
              <h5 class="out-of-stock-text" style="color: red;">Sản phẩm tạm thời hết hàng</h5>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <div class="empty-cart-box" *ngIf="cart && cart.items.length == 0">
    <i class="ri-shopping-cart-line"></i>
    <h5>Bạn chưa có sản phẩm nào trong giỏ hàng!</h5>
  </div>
  <div class="bottom-box" *ngIf="cart && cart.items.length > 0" >
    <div class="price-box">
      <h4 class="fw-bold">Tổng</h4>
      <h4 class="fw-bold theme-color">{{ cartTotal | currencySymbol }}</h4>
    </div>
    <div class="button-group">
      <a [routerLink]="['/cart']" class="btn btn-sm cart-button theme-bg-color text-white gap-2" (click)="cartToggle()">
        <img src="assets/svg/shopping-bag 1.svg" />
        XEM GIỎ HÀNG
      </a>
    </div>
  </div>
</div>
<!-- <div
  class="bg-overlay"
  [class.show]="cartStyle == 'cart_sidebar' && sidebarCartOpen"
  (click)="cartStyle == 'cart_sidebar' && cartToggle()"
></div> -->
<!-- Free Shipping Celebration -->
<!-- <div
  class="confetti-wrapper"
  [class.show]="confetti == 1 && sidebarCartOpen"
>
  <ng-container *ngFor="let item of confettiItems">
    <div class="confetti-{{ item }}"></div>
  </ng-container>
</div> -->
<!-- Variation Customize -->
<!-- <app-variation-modal #variationModal></app-variation-modal> -->