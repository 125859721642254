<ng-template #addressModal let-modal>
  <div class="modal-header">
    <h3 class="fw-semibold">Thông tin địa chỉ</h3>
    <app-button
      [class]="'btn btn-close'"
      [spinner]="false"
      [id]="'address_modal_close_btn'"
      (click)="modal.dismiss('Cross click')"
    >
      <i class="ri-close-line"></i>
    </app-button>
  </div>
  <form [formGroup]="form" class="" (ngSubmit)="submit()">
    <div class="modal-body address-form">
      <div class="row g-4">
        <h3>Thông tin người nhận</h3>
        <div class="col-6">
          <div class="">
            <label class="form-label" for="pname">Họ tên người nhận<span style="color: #dd0000">*</span></label>
            <input
              class="form-control"
              id="name"
              type="text"
              placeholder=""
              formControlName="name"
            />
            <div
              class="invalid-feedback"
              *ngIf="form?.controls?.['name']?.touched && form.controls['name']?.errors?.['required']"
            >
              Đề nghị nhập họ tên người nhận
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="">
            <label class="form-label" for="address1">Số điện thoại<span style="color: #dd0000">*</span></label>
            
            <input
              type="tel"
              class="form-control"
              formControlName="phone"
              placeholder=""
              name="phone"
            />
            <div
              class="invalid-feedback"
              *ngIf="form.controls['phone'].touched && form.controls['phone'].errors?.['required']"
            >
              Cần nhập số điện thoại
            </div>
            <div
              class="invalid-feedback"
              *ngIf="form.controls['phone'].touched && form.controls['phone'].value?.length > 0 &&
              (form.controls['phone'].value?.length > 10 ||
              form.controls['phone'].value?.length < 10 ||
              !form.controls['phone'].value?.startsWith('0')
              )"
            >
              Sai định dạng số điện thoại
            </div>
          </div>
        </div>
        <h3>Địa chỉ nhận hàng</h3>
        <div class="col-6">
          <div class="">
            <label class="form-label" for="address1">Tỉnh/thành phố<span style="color: #dd0000">*</span></label>

            <!-- <select
              class="form-control form-select"
              id="province"
              aria-label="Default select example"
              formControlName="province"
              (change)="loadDistrictsByProvince($event)"
              placeholder="Tỉnh/ Thành phố">
              <option value="" disabled selected>Tỉnh/Thành phố</option>
              <ng-container *ngFor="let province of provinces">
                <option value="{{province.code}}"  [selected]="province.code == selectedProvince">
                  {{ province.name }}
                </option>
              </ng-container>

            </select> -->
            <select2
              class="form-control form-select select2"
              id="province"
              aria-label="Default select example"
              formControlName="province"
              placeholder="Tỉnh/Thành phố *"
              [data]="select2Provinces"
              [value]="selectedProvince"
              (update)="loadDistrictsByProvince($event)"
            >
            </select2>
            <div
              class="invalid-feedback"
              *ngIf="form?.controls?.['province']?.touched && form?.controls?.['province']?.errors?.['required']"
            >
              Đề nghị nhập tỉnh/thành phố
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="">
            <label class="form-label" for="address1">Quận/Huyện<span style="color: #dd0000">*</span></label>
            <!-- <select
              class="form-control form-select"
              id="district"
              aria-label="Default select example"
              formControlName="district"
              (change)="loadWardsByDistrict($event)"
              placeholder="Quận Huyện">
              <option value="" disabled>Quận Huyện</option>
              <ng-container *ngFor="let district of districts">
                <option value="{{district.code}}" [selected]="district.code == selectedDistrict" >
                  {{ district.name }}
                </option>
              </ng-container>
            </select> -->
            <select2
              class="form-control form-select select2"
              id="district"
              aria-label="Default select example"
              formControlName="district"
              placeholder="Quận Huyện *"
              [data]="select2Districts"
              [value]="selectedDistrict"
              (update)="loadWardsByDistrict($event)"
            >
            </select2>
            <div
              class="invalid-feedback"
              *ngIf="form?.controls?.['district']?.touched && form?.controls?.['district']?.errors?.['required']"
            >
              Đề nghị nhập quận/huyện
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="">
            <label class="form-label" for="address1">Phường/Xã<span style="color: #dd0000">*</span></label>
            <!-- <select
              class="form-control form-select"
              id="ward"
              aria-label="Default select example"
              formControlName="ward"
              placeholder="Phường Xã">
              <option value="" disabled selected>Phường Xã</option>
              <ng-container *ngFor="let ward of wards">
                <option value="{{ward.code}}" [selected]="ward.code == selectedWard">
                  {{ ward.name }}
                </option>
              </ng-container>
            </select> -->
            <select2
              class="form-control form-select select2"
              id="ward"
              aria-label="Default select example"
              formControlName="ward"
              placeholder="Phường Xã *"
              [data]="select2Wards"
              [value]="selectedWard"
            >
            </select2>
            <div
              class="invalid-feedback"
              *ngIf="form?.controls?.['ward']?.touched && form.controls['ward']?.errors?.['required']"
            >
              Đề nghị nhập phường/xã
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="">
            <label class="form-label" for="address1">Địa chỉ<span style="color: #dd0000">*</span></label>
            <input
              class="form-control"
              id="address"
              type="text"
              placeholder=""
              formControlName="address"
              numbersOnly
            />
            <div
              class="invalid-feedback"
              *ngIf="form?.controls?.['address']?.touched && form.controls['address']?.errors?.['required']"
            >
              Đề nghị nhập địa chỉ
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="DefaultCheck" [checked]="checkDefault" (change)="changeDefaultAddress($event)">
            <label class="form-check-label" for="flexSwitchCheckChecked"> Đặt làm mặc định</label>
          </div>

        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">

        <app-button
          [class]="'btn theme-bg-color btn-md fw-bold text-light'"
          [id]="'submit_address_btn'"
        >
          Lưu thay đổi
        </app-button>
      </div>
    </div>
  </form>
</ng-template>
