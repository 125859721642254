<div class="mobile-menu d-md-none d-block mobile-cart">
  <ul>
    <li [class.active]="menu.active" [routerLink]="menu.path" *ngFor="let menu of menuItem">
      <a href="javascript:void(0)" (click)="activeMenu(menu)" style="font-size: 12px;">
        <small class="badge" *ngIf="cartTotal && menu.title == 'Cart'">
          {{ cartTotal }}
        </small>
        <i class="{{ menu.icon }}-line deactivated"></i>
        <i class="{{ menu.icon }}-fill activated"></i>
        <span>{{ menu.title }}</span>
      </a>
    </li>
  </ul>
</div>

