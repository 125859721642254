import { Component, Input } from '@angular/core';
import { Link } from '@/app/shared/interface/common.interface';
import { ContactUs } from '@/app/shared/interface/common.interface';
import { Logo } from '@/app/shared/interface/common.interface';

@Component({
  selector: 'app-footer-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss']
})
export class LinksComponent {
  
  @Input() links: Link[] = [];
  @Input() name: string = '';
  @Input() contactname: string = '';
  @Input() contactes: ContactUs[] = [];
  @Input() logos: Logo[] = [];
  @Input() logoname: string = '';

  telPhone(contactNumber: string){
    window.open("tel:" + this.removeDots(contactNumber));
  }

  removeDots(str: string): string {
    return str.replace(/\.+/g, "");
  }
}
