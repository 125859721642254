import { Component, Inject, Input, PLATFORM_ID, TransferState, afterNextRender } from '@angular/core';
// import { Observable } from 'rxjs';
import { ProductService } from '@/app/shared/services/product.service';
import { CommonService } from '@/app/shared/services/common.service';
import { Product, ProductInfoType, ProductModel } from '@/app/shared/interface/product.interface';
import * as data from '@/app/shared/data/owl-carousel';
import { firstValueFrom } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { TOKEN } from '../../services/auth.service';

@Component({
  selector: 'app-top-sale-home',
  templateUrl: './top-sale-home.component.html',
  styleUrl: './top-sale-home.component.scss'
})
export class TopSaleHomeComponent {

  public product: Product;
  @Input() title: string;
  @Input() searchTerm: string;

  public relatedproducts: Product[] = [];
  public productSimilartSlider = data.productSimilartSlider;

  constructor(
    private productService: ProductService, 
    private commonService: CommonService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.getRelatedProducts(this.searchTerm);
      
      window.addEventListener("resize", () => {
        this.changeItemsPerSlide();
      })
    };
  }

  ngOnInit() {
  }

  changeItemsPerSlide() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth <= 756) {
        this.productSimilartSlider.responsive![0].items = 2;
      }
      else {
        this.productSimilartSlider.responsive![0].items = 6;
      }
    }
  }

  getRelatedProducts(searchTerm: string) {
    const body = {
      limit: 10,
      searchTerm: searchTerm
    }

    const token = this.transferState.get(TOKEN, '');

    if(token) {
      this.productService.searchProductsForMember(JSON.stringify(body)).subscribe((res: any) => {
        if (res && res.products) {
          this.relatedproducts = res.products;
          this.productSimilartSlider.items = res.total;
        }
      });
    }
    else{
      this.productService.searchProductsForGuest(JSON.stringify(body)).subscribe((res: any) => {
        if (res && res.products) {
          this.relatedproducts = res.products;
          this.productSimilartSlider.items = res.total;
        }
      });
    }
  }
}
