import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { Order, OrderModel, ReOrderResponseModel } from "../interface/order.interface";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { Subject } from "@microsoft/signalr";
import { AddUserOrderModel } from "../interface/cart.interface";

@Injectable({
  providedIn: "root",
})
export class OrderService {

  public setlectedOrder: Order;

  constructor(private http: HttpClient,
    private route: Router) { }

  getOrders(body: string) {
    return this.http.post<OrderModel>(`${environment.baseURL}retrieve-user-orders`, body, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  createUpdateOrderV1(payload: AddUserOrderModel) : Observable<OrderModel>{
    return this.createUpdateOrder(JSON.stringify(payload));
  }
  createUpdateOrder(body: string) {
    return this.http.post<OrderModel>(`${environment.baseURL}order`, body, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  createOrderGuest(message: string) {
    return this.http.post(`api/log`, {message}, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  reOrder(orderId: string): Observable<ReOrderResponseModel> {
    let url = this.route.createUrlTree([`.`], { queryParams: { orderId: orderId } });
    return this.http.post<ReOrderResponseModel>(`${environment.baseURL}reorder${url.toString().substring(1)}`, '', {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

}
