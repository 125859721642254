import { Component, Input } from '@angular/core';
import { Service } from '@/app/shared/interface/common.interface';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent {

  @Input() data: Service[];

}
