<div class="bg-effect mb-5" style="max-width: 1400px; margin: auto;" *ngIf="isBrowser">
  <!-- Home Section Start -->
  <section class="home-section">
    <app-home-banner [data]="home_banner"></app-home-banner>
  </section>
  <!-- Home Section End -->

  <!-- Banner Section Start -->
  <section class="">
    <app-service [data]="serviceshome" class="p-2" ></app-service>
    
    <app-theme-banner 
      [style]="'horizontal'"
      [banners]="banners" *ngIf="!isAuthenticated"
    ></app-theme-banner>

    <app-wservice-component
      [dataupper]="dataupper"
      [databot]="databot" *ngIf="!isAuthenticated"
    ></app-wservice-component>

    <app-registry-info-home *ngIf="!isAuthenticated"></app-registry-info-home>

    <app-partner-logo *ngIf="!isAuthenticated"></app-partner-logo>

    <app-feedback-home
      [style]="'horizontal'"
      [banners]="feedbacks" *ngIf="!isAuthenticated"
    ></app-feedback-home>
    
    <!-- <app-categories-home *ngIf="isAuthenticated"></app-categories-home> -->

    <app-flashsale-home [title]="'flash sale'" [type] = 1 
    *ngIf="
      isAuthenticated && 
      flashsaleEvent && 
      (flashsaleEvent.currentPeriod?.products?.length || flashsaleEvent.nextPeriod?.products?.length)" 
    [flashsale]="flashsaleEvent"
    [searchTerm]="'flashsale'"></app-flashsale-home>

    <app-brand-home [style]="'horizontal'" *ngIf="isAuthenticated" [banners]="banners"></app-brand-home>

    <app-top-sale-home 
      [title]="'Sản phẩm bán chạy'"
      [searchTerm]="'a'" *ngIf="isAuthenticated"
    ></app-top-sale-home>

    <app-top-sale-home 
      [title]="'Sản phẩm điểm'"
      [searchTerm]="'bs'" *ngIf="isAuthenticated"
    ></app-top-sale-home>

    <app-top-sale-home 
      [title]="'Có thể bạn quan tâm'"
      [searchTerm]="'ca'" *ngIf="isAuthenticated"
    ></app-top-sale-home>

    <app-news-home></app-news-home>
  </section>
  <!-- Banner Section End -->
</div>