<div *ngIf="user && giftInfo" class="ms-3 mobile-mg-0">
  <div class="d-none col-xxl-12 col-lg-12 col-md-12 col-sm-12 gap-3 bg-white p-4 rounded-3 mobile-pd d-lg-flex d-xl-flex">
    <div class="col-5">
      <div class="div-border" *ngIf="user">
        <div class="d-flex gap-2 align-items-start">
          <img src="assets/svg/leaf_green.svg" alt="leaf" class="img-fluid" />
          <div class="d-grid text-start">
            <h2 class="theme-color">{{user.giftPoint ? user.giftPoint : 0}}</h2>
            <span style="color: #717171">Số lá xanh khả dụng</span>
          </div>
        </div>
      </div>
      <div class="text-start" [innerHTML]="giftInfo.giftDescriptionMini">
      </div>
    </div>
    <div class="col-7 d-none d-lg-block d-xl-block">
      <div [innerHTML]="giftInfo.giftDescription">
      </div>
    </div>
  </div>

  <div class="d-lg-none d-xl-none d-md-grid d-sm-grid col-xxl-12 col-lg-12 col-md-12 col-sm-12 gap-3 bg-white rounded-3 mb-3">
    <div class="div-border" *ngIf="user">
      <div class="d-flex gap-2 align-items-start">
        <img src="assets/svg/leaf_green.svg" alt="leaf" class="img-fluid" />
        <div class="d-flex align-items-center">
          <div class="d-grid text-start">
            <h2 class="theme-color">{{user.giftPoint ? user.giftPoint : 0}}</h2>
            <span style="color: #717171">Số lá xanh khả dụng</span>
          </div>
        </div>
      </div>
      <div class="text-start" [innerHTML]="giftInfo.giftDescriptionMini">
      </div>
    </div>
  </div>
  <div class="d-lg-none d-xl-none d-md-grid d-sm-grid col-xxl-12 col-lg-12 col-md-12 col-sm-12 gap-3 bg-white p-2 rounded-3">
    <div [innerHTML]="giftInfo.giftDescription">
    </div>
  </div>

  <div class="bg-white mt-2 p-4 rounded-3 mobile-pd">
    <div class="border-0 border-bottom">
      <div class="py-2 d-flex gap-2">
        <div class="col-5 col-sm-5 dp border d-flex align-items-center ps-3" style="padding-right: 2rem;">
          <input class="border-0" placeholder="Từ ngày" name="dp1" style="width: -webkit-fill-available;"
            [(ngModel)]="startTime.date" [ngModelOptions]="{standalone: true}" ngbDatepicker
            #ds1="ngbDatepicker" (click)="ds1.toggle()" />
          <img src="assets/svg/calendar 1.svg" (click)="ds1.toggle()" />
        </div>
        <div class="col-5 col-sm-5 dp border d-flex align-items-center ps-3" style="padding-right: 2rem;">
          <input class="border-0" placeholder="Đến ngày" name="dp2" style="width: -webkit-fill-available;"
            [(ngModel)]="endTime.date" [ngModelOptions]="{standalone: true}" ngbDatepicker
            #ds2="ngbDatepicker" (click)="ds2.toggle()" />
          <img src="assets/svg/calendar 1.svg" (click)="ds2.toggle()" />
        </div>
        <div>
          <button
            class="border-0 bg-transparent p-0"
            (click)="search()"
          >
            <i
              class="ri-search-line d-flex justify-content-center align-items-center search-icon"
            ></i>
          </button>
        </div>
      </div>
    </div>
    <div class="card ps-0 pe-0" *ngIf="giftList.length">
      <div class="card-body">
        <div class="tracking-wrapper table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th *ngFor="let col of headerColumns" scope="col" class="d-none d-xl-table-cell d-lg-table-cell">
                  {{ col }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of giftList">
                <td class="product-image">
                  <div class="d-flex align-items-center gap-3">
                    <div class="d-grid gap-2">
                      <h6 class="d-none d-lg-block d-xl-block">{{ item.usedDate | date: 'dd/MM/yyyy' }}</h6>
                      <div class="d-lg-none d-xl-none d-grid gap-2">
                        <h6>Thời gian: {{ item.usedDate | date: 'dd/MM/yyyy' }}</h6>
                        <h6>Nội dung: 
                          <span *ngIf="item.point > 0 && !item.userUpdate">Tích lũy {{ item.point }} lá xanh cho đơn hàng</span>
                          <span *ngIf="item.point < 0 && !item.userUpdate">Đổi quà tặng tích lũy lá xanh</span>
                          <span *ngIf="item.userUpdate">Admin update</span>
                        </h6>
                        <h6 *ngIf="item.point > 0" class="theme-color">Số lá xanh: +{{ item.point }}</h6>
                        <h6 *ngIf="item.point < 0" style="color: #B50303;">Số lá xanh: {{ item.point }}</h6>
                        <h6 *ngIf="item.orderId"><a href="javascript:void(0)" [routerLink]="['/account/my-order', item.orderId ]"><span class="text-gray">Mã đơn hàng: </span>{{ item.orderId }}</a></h6>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="col-xxl-5 col-lg-5 d-none d-xl-table-cell d-lg-table-cell">
                  <h6>
                    <span *ngIf="item.point > 0 && !item.userUpdate">Tích lũy {{ item.point }} lá xanh cho đơn hàng</span>
                    <span *ngIf="item.point < 0 && !item.userUpdate">Đổi quà tặng tích lũy lá xanh</span>
                    <span *ngIf="item.userUpdate">Admin update</span>
                  </h6>
                </td>
                <td class="d-none d-xl-table-cell d-lg-table-cell text-start">
                  <h6 *ngIf="item.point > 0" class="theme-color">+{{ item.point }}</h6>
                  <h6 *ngIf="item.point < 0" style="color: #B50303;">{{ item.point }}</h6>
                </td>
                <td class="d-none d-xl-table-cell d-lg-table-cell text-start">
                  <h6><a href="javascript:void(0)" [routerLink]="['/account/my-order', item.orderId ]"><span class="text-gray"></span>{{ item.orderId }}</a></h6>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <app-collection-paginate></app-collection-paginate>
  </div>
</div>