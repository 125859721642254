<section class="service-section">
  <div class="container-fluid-lg container-fluid-md container-fluid-xs bg-white">
    <div class="p-5 row row-cols-2 row-cols-md-2 row-cols-sm-2">
      <div class="col-5 col-md-5 pt-4 mobile-w-100">
        <div class="row text-theme fs-6 pb-2">
          <span >{{ dataform.titleupper }}</span>
        </div>
        <div class="row text-theme fs-3 pb-2">
          <span 
            ><strong class="d-block fw-bold" style="width: fit-content"
              >{{ dataform.titlebottom }}
              <div class="d-flex">
                <div
                  class="col-5 rounded-5"
                  style="background-color: #ffc803; height: 5px"
                ></div>
                <div
                  class="col-7 rounded-5"
                  style="background-color: #247636; height: 5px"
                ></div></div></strong
          ></span>
        </div>

        <div class="row lh-base p-2z" style="font-size: 20px">
          <span>
            {{ dataform.description }}
          </span>
        </div>
        <div class="text-theme pt-3">
          <button class="btn rounded-pill bg-theme text-white" (click)="gotoIntrocude()">Xem thêm</button>
        </div>
      </div>

      <div
        class="col-7 col-md-7 border-radius-xxl-5 p-4 border-radius-xxl-5 rounded-4 mobile-w-100 gap-3 d-grid"
        style="background-color: #f7fdf8"
      >
        <div class="row g-3 row-cols-xxl-3 row-cols-lg-3 row-cols-3 row-cols-sm-3">
          <div *ngFor="let wserviceup of dataupper">
            <div class="rounded-4 pb-4 pt-4 bg-white justify-content-center h-100">
              <div class="text-center border-2 mx-4 pt-2 pb-2 image">
                <img
                  [src]="wserviceup.image_url"
                  [alt]="wserviceup?.title"
                  class="col-5"
                />
              </div>

              <div class="text-center">
                <h4 class="pb-2 pt-3">
                  <strong>{{ wserviceup?.title }}</strong>
                </h4>
                <h6
                  class="text-content text-theme p-2 fs-5"
                  style="color: #247636"
                >
                  {{ wserviceup?.description }}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-3 row-cols-xxl-3 row-cols-lg-3 row-cols-3 row-cols-sm-3">
          <div *ngFor="let wservicebot of databot">
            <div class="rounded-4 pb-4 pt-4 bg-white justify-content-center  h-100">
              <div class="text-center border-2 mx-4 pt-2 pb-2 image">
                <img
                  [src]="wservicebot.image_url"
                  [alt]="wservicebot?.title"
                  class="col-5"
                />
              </div>

              <div class="service-detail text-theme text-center d-flex">
                <h4 class="pb-2">
                  <strong>{{ wservicebot?.title }}</strong>
                </h4>
                <h6
                  class="text-content text-theme p-2 fs-5"
                  style="color: #247636"
                >
                  {{ wservicebot?.description }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
