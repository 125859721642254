import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
// import { Select } from '@ngxs/store';
import { Breadcrumb } from '../../../shared/interface/breadcrumb';
import { Router } from '@angular/router';
// import { ThemeOptionState } from '../../../shared/state/theme-option.state';
// import { Option } from '../../../shared/interface/theme-option.interface';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component {

  // @Select(ThemeOptionState.themeOptions) themeOption$: Observable<Option>;
  themeOption: any;

  public breadcrumb: Breadcrumb = {
    title: "404",
    items: [{ label: "404", active: true }]
  }

  phoneNumber: string = "0878929789";

  constructor(
    private router: Router
  ) { }

  gotoHome() {
    this.router.navigateByUrl("/", { replaceUrl: true });
  }

  callPhone() {
    window.open(`tel:${this.phoneNumber}`);
  }

}
