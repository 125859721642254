<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
<div class="container-fluid-lg">
    <article *ngIf="postDetail" class="ck-content">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 post-detail">
                <header class="entry-header">
                    <h1 class="entry-title">{{postDetail.postTitle}}</h1>
                </header>
                <div class="entry-content">
                    <div *ngIf="postDetail.postThumbnailUrl" class="text-center">
                        <img [src]="postDetail.postThumbnailUrl" [alt]="postDetail.postTitle"  class="rounded w-100">
                    </div>
                    <div [innerHTML]="postDetail.postDescription"></div>
                    <app-blog-post-menu [blogPostTag]="pageType"></app-blog-post-menu>
                    <div class="post-content" [innerHTML]="postDetail.postContent"></div>
                </div>
            </div>
            <div class="col-xs-0 col-sm-0 col-md-3 col-lg-3">
                
                <ng-container *ngFor="let cat of postsByCategory">
                    <div class="relative-side-bar bg-white rounded rounded-3 mb-3">
                        <div class="relative-side-bar-header">
                            <h3>{{cat.categoryName}}</h3>
                        </div>
                        <div class="relative-side-bar-body">
                            <ul>
                                <ng-container *ngFor="let p of cat.posts">
                                    <li class="nav-item" [ngClass]="currentRouter.includes(p.slug) ? 'active' : ''">
                                        <a class="nav-link" aria-current="page" routerLink="/{{p.slug}}">{{p.postTitle}}</a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </article>
</div>