<ng-template #forgotPasswordModal let-modal>
    <div class="modal-body address-form">
      <div class="row justify-content-center text-center" ><img style="width: 50%;" src="assets/images/forgot-password.png" alt="forgotPassword" /></div>
      <div class="row justify-content-center text-center"><h3 class="fw-semibold">Bạn quên mật khẩu?</h3></div>
      <br>
      <div class="row justify-content-center text-center">
        <div class="col">
          <p
            >Quý khách vui lòng liên hệ hotline <br>0878929789 để lấy lại mật khẩu. Giá Thuốc <br>Tốt xin chân thành cảm ơn!
          </p>
        </div>
      </div>
      <div class="row justify-content-center p-2">
        <div class="col-12 text-center">
          <p>Bạn chưa có tài khoản  <a href="javascript:void(0);" (click)="handleButtonClick($event)" ><b>Đăng ký tại đây</b></a></p>
        </div>
      </div>
    </div>
</ng-template>
