import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  PLATFORM_ID,
  TransferState,
  ViewChild,
} from '@angular/core';
// import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { User } from '../../../shared/interface/user.interface';
// import { AccountState } from '../../../shared/state/account.state';
// import { Notification } from '../../../shared/interface/notification.interface';
// import { NotificationState } from '../../../shared/state/notification.state';
// import { Logout } from '../../../shared/action/auth.action';
import { ConfirmationModalComponent } from '@/app/shared/widgets/modal/confirmation-modal/confirmation-modal.component';
import { AuthService, IS_AUTHENTICATED } from '../../services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { AccountService } from '../../services/account.service';
import { CommonService } from '../../services/common.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { isPlatformServer } from '@angular/common';
import { REQUEST } from '@/express.tokens';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() show: boolean;
  @Output() menu: EventEmitter<boolean> = new EventEmitter();
  @Output() activeMenu: EventEmitter<any> = new EventEmitter();
  @ViewChild('confirmationModal') ConfirmationModal: ConfirmationModalComponent;

  public user: any;
  public fullName = '';
  public avatarUrl: string = '';
  public unreadNotificationCount: number;
  public sideBar: any;
  public active: string = '';
  public isAuthenticated: boolean = false;

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private router: Router,
    private commonService: CommonService,
    private cookieService: SsrCookieService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) private request: any,
    private transferState: TransferState
  ) {
    // this.notification$.subscribe((notification) => {
    //   this.unreadNotificationCount = notification?.filter(item => !item.read_at)?.length;
    // });
    if (isPlatformServer(platformId)) {
      this.request.cookies = this.request.headers.cookie;
      // this.isAuthenticated = this.commonService.getCookie(this.request.cookies, 'token') ? true : false;
    }
    this.isAuthenticated = this.authService.isAuthenticated();
  }

  ngOnInit() {
    // const token = this.cookieService.get('token');
    // const userName = this.cookieService.get('username');
    // let jwtToken = this.commonService.parseJwt(token!);
    // this.isAuthenticated =
    //   jwtToken && jwtToken.UserName == userName!
    //     ? true
    //     : false;
    if (this.isAuthenticated) {
      this.setData();
    }
    // this.getUserSidebar();
    this.router.events.subscribe({
      next: (val) => {
        if (val instanceof NavigationEnd) {
          if (this.router.url.indexOf('account') != -1 && this.sideBar) {
            this.setActiveSideBar();
          }
        }
      },
    });

    this.commonService.authEvent.subscribe({
      next: (res: any) => {
        if (res) {
          this.isAuthenticated = res;
          if (this.isAuthenticated) {
            this.setData();
          }
        }
      },
    });
  }

  setData() {
    this.accountService.getUserData.subscribe({
      next: (res: User) => {
        this.fullName = res.fullName
        this.avatarUrl = res.avatarUrl;
      },
    });
    this.getUserSidebar();
  }

  setActiveSideBar() {
    for (let s of this.sideBar) {
      if (s.path.split('/').length > 2) {
        if (s.path.split('/')[1] == this.router.url.split('/')[2].split('?')[0]) {
          this.active = s.name;
          this.activeMenu.emit(s);
          break;
        }
      }
    }
  }

  getUserSidebar() {
    this.accountService.getUserSidebar().subscribe({
      next: (res: any) => {
        if (res) {
          this.sideBar = res.filter((item: any) => item.display == true);
          this.setActiveSideBar();
        }
      },
    });
  }

  logout(value: boolean) {
    if (value) {
      this.authService.logout();
      this.router.navigateByUrl('/', { replaceUrl: true });
    }
  }

  openMenu(value: boolean) {
    this.menu.emit(value);
  }
}
